const CameraIcon = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4126_4000"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="37"
        height="37"
      >
        <rect width="37" height="37" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4126_4000)">
        <path
          d="M29.2915 10.7915V7.70817H26.2082V4.62484H29.2915V1.5415H32.3748V4.62484H35.4582V7.70817H32.3748V10.7915H29.2915ZM16.9582 26.979C18.8853 26.979 20.5233 26.3045 21.8722 24.9556C23.2212 23.6066 23.8957 21.9686 23.8957 20.0415C23.8957 18.1144 23.2212 16.4764 21.8722 15.1274C20.5233 13.7785 18.8853 13.104 16.9582 13.104C15.0311 13.104 13.3931 13.7785 12.0441 15.1274C10.6952 16.4764 10.0207 18.1144 10.0207 20.0415C10.0207 21.9686 10.6952 23.6066 12.0441 24.9556C13.3931 26.3045 15.0311 26.979 16.9582 26.979ZM16.9582 23.8957C15.879 23.8957 14.9669 23.5231 14.2217 22.778C13.4766 22.0328 13.104 21.1207 13.104 20.0415C13.104 18.9623 13.4766 18.0502 14.2217 17.305C14.9669 16.5599 15.879 16.1873 16.9582 16.1873C18.0373 16.1873 18.9495 16.5599 19.6946 17.305C20.4398 18.0502 20.8123 18.9623 20.8123 20.0415C20.8123 21.1207 20.4398 22.0328 19.6946 22.778C18.9495 23.5231 18.0373 23.8957 16.9582 23.8957ZM4.62484 32.3748C3.77692 32.3748 3.05105 32.0729 2.44723 31.4691C1.84341 30.8653 1.5415 30.1394 1.5415 29.2915V10.7915C1.5415 9.94359 1.84341 9.21772 2.44723 8.6139C3.05105 8.01008 3.77692 7.70817 4.62484 7.70817H9.48109L12.3332 4.62484H23.1248V10.7915H26.2082V13.8748H32.3748V29.2915C32.3748 30.1394 32.0729 30.8653 31.4691 31.4691C30.8653 32.0729 30.1394 32.3748 29.2915 32.3748H4.62484Z"
          fill="#0BA98D"
        />
      </g>
    </svg>
  );
};

export default CameraIcon;
