import { useFormikContext } from "formik";
import { CheckCircleOutlined } from "@ant-design/icons";
import $projectManagement from "../../../store/manage-project";
import { entryStatus } from "./ManageProject.utils";

import FormikErrorMessage from "../../primitives/FormikErrorMessage";

const SelectTruckFormPhase = () => {
  const form = useFormikContext();
  const trucks = form.values.haulers;
  const lastSelectedTruckId =
    $projectManagement.selectors.useLastSelectedTruckId();

  return (
    <div className="space-y-4">
      <h1 className="text-center">Trucks available from 7/25/24</h1>
      <div className="w-full min-w-[300px] max-h-[300px] overflow-scroll min-h-[300px] bg-dark-bg rounded-md">
        {trucks.map((truck, index) => {
          const status = truck.status || entryStatus.UNASSIGNED;
          const isSelected = status === entryStatus.ADDED;
          const isFinalised = status === entryStatus.FINALIZED;
          const isEvenTile = (index & 1) === 0;
          const firstTileRadius = index === 0 ? "rounded-t-md" : "";
          const lastTileRadius =
            index === trucks.length - 1 ? "rounded-b-md" : "";
          return (
            <div
              onClick={() => {
                const truckObjectKey = `haulers.${index}`;
                if (isFinalised) return;
                if (lastSelectedTruckId) {
                  form.setFieldValue(
                    lastSelectedTruckId + ".status",
                    entryStatus.UNASSIGNED
                  );
                }
                $projectManagement.actions.setLastSelectedTruckId(
                  truckObjectKey
                );
                form.setFieldValue(
                  truckObjectKey + ".status",
                  entryStatus.ADDED
                );
                $projectManagement.actions.setSelectedTruckId(truckObjectKey);
              }}
              className={`flex items-center justify-between cursor-pointer  px-4 py-3 
                ${isEvenTile ? " bg-[#242424]" : "bg-[#161616]"} 
                ${firstTileRadius} ${lastTileRadius} 
                ${isSelected ? "border-2 border-primary" : ""}
            `}
            >
              <div className="flex items-center gap-4">
                <div className="w-8 h-8 rounded-[50%] bg-pastel-red-100" />
                <h1 className=" select-none font-bold">{truck.truck_type}</h1>
              </div>
              <div className="flex items-center gap-4">
                {isFinalised && <CheckCircleOutlined />}
              </div>
            </div>
          );
        })}
      </div>
      <FormikErrorMessage name="haulers" />
    </div>
  );
};

export default SelectTruckFormPhase;
