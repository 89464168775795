import { useMutation } from "@tanstack/react-query";
import { updateHaul } from "../services/trucker";
import { Mutations } from ".";

const useHaulUpdate = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: updateHaul,
    mutationKey: [Mutations.updateHaul],
    onSuccess,
  });
};

export default useHaulUpdate;
