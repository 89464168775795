import { API } from "../../config/AppConstants";
import { apiHelper } from "../instance";

export const submitHaul = async (payload) => {
  const { data } = await apiHelper.post(
    API.API_ENDPOINTS.TRUCKER.HAULS,
    payload
  );
  return data;
};

export const fetchHaulDetails = async ({ job_id, assignment_id }) => {
  const { data } = await apiHelper.get(API.API_ENDPOINTS.TRUCKER.HAULS, {
    params: {
      job_id,
      method: "getHauls",
      assignment_id,
    },
  });
  return data;
};

export const updateHaul = async (payload) => {
  const { data } = await apiHelper.put(
    API.API_ENDPOINTS.TRUCKER.HAULS,
    payload
  );
  return data;
};
