import { Button } from "antd";
import { useParams } from "react-router-dom";
import Toastr from "toastr";

import $diverProjectManagement from "../../../../store/manage-driver-project";
import useHauls from "../../../../api/queries/useHauls";
import useUpdateTrip from "../../../../api/mutations/useUpdateTrip";
import Loader from "../../../primitives/Loader";
import Haul from "../../../../helpers/haulAssignmentHelper";
import Assignment from "../../../../helpers/assignmentHelper";
import { TripStatus } from "../../../../enums/status";
import GeoLocationHelper from "../../../../helpers/geoLocationHelper";
import useGeoLocation from "../../../../api/hooks/useGeoLocation";
import Job from "../../../../helpers/jobHelper";

const ArrivedAtDestinationFormPhase = () => {
  const { assignment_id } = useParams();

  const {
    location: currentLocation,
    error,
    loading: isFetchingCurrentLocation,
  } = useGeoLocation();

  const { data: hauls, isLoading: isHaulsLoading } = useHauls({
    assignment_id,
  });

  const { mutate: updateTripStatus, isPending: isUpdatePending } =
    useUpdateTrip({
      onSuccess: () => {
        $diverProjectManagement.actions.nextPhase();
      },
    });

  if (isHaulsLoading) {
    return <Loader />;
  }

  const haulsData = hauls[0];
  const haul = new Haul(haulsData);
  const job = new Job(haulsData.job);
  const assignmentData = haul.assignmentById(assignment_id);
  const assignment = new Assignment(assignmentData);
  const currentTrip = assignment.getTripByAssigmentId(assignment_id);

  const getCoordinatesToCheckWith = () => {
    if (job.isExportJob) {
      return {
        lat: assignment.mineCoordinates.lat,
        lng: assignment.mineCoordinates.lng,
      };
    }
    return {
      lat: job.gpsCoordinates.lat,
      lng: job.gpsCoordinates.lng,
    };
  };

  const validateUserLocation = () => {
    const { lat, lng } = getCoordinatesToCheckWith();
    return GeoLocationHelper.isLocationInRadius(
      { lat: currentLocation.latitude, lng: currentLocation.longitude },
      { lat, lng },
      500
    );
  };

  const handleSubmit = () => {
    if (error && error.message) {
      Toastr.error(
        "Turn On Location Services to Allow TruckingEZ to Determine Your Location"
      );
      return;
    }

    if (!validateUserLocation()) {
      Toastr.error("Location Unreached");
      return;
    }

    const payload = {
      trip_id: currentTrip?._id,
      status: TripStatus.ARRIVED_AT_DESTINATION,
    };

    updateTripStatus(payload);
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center items-center gap-8">
        <iframe
          title="map"
          width="100%"
          id="gmap_canvas"
          className="rounded w-96 h-96"
          src={`https://maps.google.com/maps?q=${currentLocation.latitude},${currentLocation.longitude}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
        ></iframe>
        {isFetchingCurrentLocation ? "Fetching Location.." : null}
        <Button onClick={handleSubmit} loading={isUpdatePending}>
          Arrived at Destination
        </Button>
      </div>
    </div>
  );
};

export default ArrivedAtDestinationFormPhase;
