import { create } from "zustand";

import { immer } from "zustand/middleware/immer";

import { devtools } from "zustand/middleware";

const useProjectManagementStore = create(
  devtools(
    immer((set, get) => ({
      selectedTruckId: null,
      lastSelectedTruckId: null,
      selectedDriverId: null,
      lastSelectedDriverId: null,
      trucks: [],
      drivers: [],
    }))
  )
);

const selectors = {
  useLastSelectedTruckId: () =>
    useProjectManagementStore((state) => state.lastSelectedTruckId),
  useSelectedTruckId: () =>
    useProjectManagementStore((state) => state.selectedTruckId),
  useLastSelectedDriverId: () =>
    useProjectManagementStore((state) => state.lastSelectedDriverId),
  useSelectedDriverId: () =>
    useProjectManagementStore((state) => state.selectedDriverId),
  useTrucksStore: () => useProjectManagementStore((state) => state.trucks),
  useDriversStore: () => useProjectManagementStore((state) => state.drivers),
};

const actions = {
  set: useProjectManagementStore.setState,
  setLastSelectedTruckId: (id) => {
    useProjectManagementStore.setState({ lastSelectedTruckId: id });
  },
  setSelectedTruckId: (id) => {
    useProjectManagementStore.setState({ selectedTruckId: id });
  },

  setLastSelectedDriverId: (id) => {
    useProjectManagementStore.setState({ lastSelectedDriverId: id });
  },
  setSelectedDriverId: (id) => {
    useProjectManagementStore.setState({ selectedDriverId: id });
  },

  setTrucks: (trucks) => {
    useProjectManagementStore.setState({ trucks });
  },
  setDrivers: (drivers) => {
    useProjectManagementStore.setState({ drivers });
  },
};

/**
 * **Damaged Items Store**
 *
 * this store is used to store damages items to handle in the form
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $projectManagement = {
  actions,
  selectors,
};

export default $projectManagement;
