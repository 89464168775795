import { useQuery } from "@tanstack/react-query";
import { fetchMines } from "../services/mines";

const { Queries } = require(".");

const useMines = ({ company_id, enabled = true }) => {
  return useQuery({
    queryKey: [Queries.mines, company_id],
    queryFn: () =>
      fetchMines({
        company_id,
      }),
    enabled,
  });
};

export default useMines;
