import { TripStatus } from "../enums/status";

class AssignmentHelper {
  constructor(assignmentData) {
    this.assignmentData = assignmentData;
  }

  // Getters for basic properties
  get assignmentId() {
    return this.assignmentData._id;
  }

  get jobId() {
    return this.assignmentData.job_id;
  }

  get truckId() {
    return this.assignmentData.truck_id;
  }

  get driverId() {
    return this.assignmentData.driver_id;
  }

  get startDate() {
    return this.assignmentData.startDate;
  }

  get haulId() {
    return this.assignmentData.haul_id;
  }

  get status() {
    return this.assignmentData.status;
  }

  // Driver-specific information
  get driverName() {
    return `${this.assignmentData.driver.first_name} ${this.assignmentData.driver.last_name}`;
  }

  get driverEmail() {
    return this.assignmentData.driver.email;
  }

  get driverPhone() {
    return this.assignmentData.driver.phone;
  }

  // Tons-related information (assuming the data exists)
  get tonsCompleted() {
    return this.assignmentData.tons_completed || 0; // Default to 0 if not present
  }

  get tonsTotal() {
    return this.assignmentData.tons_total || 0; // Default to 0 if not present
  }

  get completionPercentage() {
    if (this.tonsTotal > 0) {
      return (this.tonsCompleted / this.tonsTotal) * 100;
    }
    return 0;
  }

  get trips() {
    return this.assignmentData?.trips ?? [];
  }
  getTripByAssigmentId(assigmentId) {
    return this.trips?.find(
      (trip) =>
        trip.job_assignment_id === assigmentId &&
        trip.status !== TripStatus.COMPLETED
    );
  }

  isAssignmentStarted() {
    return this.trips?.length > 0;
  }

  getTheCurrentTrip() {
    return this.trips?.find((trip) => trip.status !== TripStatus.COMPLETED);
  }

  get totalLoadCompleted() {
    return this.trips?.reduce(
      (total, trip) => total + (trip?.load_quantity ?? 0),
      0
    );
  }

  get mine() {
    return this.assignmentData?.mine;
  }

  get mineCoordinates() {
    if (this.mine?.gps) {
      const gps = this.mine.gps.split(",");
      return {
        lat: parseFloat(gps[0]),
        lng: parseFloat(gps[1]),
      };
    }
    return null;
  }
}

export default AssignmentHelper;
