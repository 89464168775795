import { useEffect } from "react";
import useSession from "../context/SessionContext";

const LogOut = () => {
  const { user, setUser, setBusiness } = useSession();

  useEffect(() => {
    if (user && user.user_login) {
      setUser(null);
      setBusiness(null);
    }
  });

  return (
    <div>
      You've been successfully logged out. Redirecting you to the homepage ...
    </div>
  );
};

export default LogOut;
