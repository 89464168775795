import React from "react";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import SendIcon from "../../components/icons/send";
import useDriverProjects from "../../api/queries/useDriverProjects";
import moment from "moment";
import Loader from "../../components/primitives/Loader";

const DriverProjects = () => {
  const navigate = useNavigate();
  const { data: projects, isLoading } = useDriverProjects(
    moment().format("YYYY-MM-DD")
  );

  if (isLoading) {
    return <Loader />;
  }

  const jobsForToday = projects?.jobsForToday || [];
  const upcomingJobs = projects?.upcomingJobs || [];

  const columnsProjectForTheDay = [
    {
      title: "Projects For Today",
      dataIndex: "job",
      key: "job",
      render: (job) => {
        return <span className="capitalize">{job.project_name}</span>;
      },
    },

    {
      title: "",
      key: "action",
      dataIndex: "action",
      render: () => <SendIcon />,
    },
  ];

  const columnUpcomingProjects = [
    {
      title: "Upcoming Projects",
      dataIndex: "job",
      key: "job",
      render: (job) => {
        return <span className="capitalize">{job.project_name}</span>;
      },
    },

    {
      title: "",
      key: "action",
      dataIndex: "action",
      render: () => <SendIcon />,
    },
  ];

  return (
    <div className="space-y-8">
      <Table
        columns={columnsProjectForTheDay}
        dataSource={jobsForToday}
        pagination={false}
        rowClassName={"cursor-pointer"}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/driver/projects/${record._id}`);
            }, // click row
          };
        }}
      />
      <Table
        columns={columnUpcomingProjects}
        dataSource={upcomingJobs}
        pagination={false}
        rowClassName={"cursor-pointer"}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/driver/projects/${record._id}`);
            }, // click row
          };
        }}
      />
    </div>
  );
};

export default DriverProjects;

// eslint-disable-next-line no-lone-blocks
{
  /* 
    <div className="w-full min-w-[300px] max-h-[300px] overflow-scroll min-h-[300px] bg-dark-bg rounded-md">
        <div className="flex justify-between bg-[#782627] py-2">
          <div className="flex-1 flex justify-center items-center font-bold">
            Projects For Today
          </div>
        </div>
        {projects.map((e) => {
          return (
            <div className="flex justify-between py-2">
              <div className="flex-1 flex justify-center items-center gap-8">
                <div className="">
                  <h1 className="">{e.name}</h1>
                </div>
                <div className="">
                  <SendIcon />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    */
}
