import React, { useState } from "react";
import livePic from "../assets/img/construction.png";
import {
  Avatar,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import logo from "../assets/img/logo_transparent.png";
import SubmitButton from "../components/Button";
import { useNavigate } from "react-router-dom";
import Toastr from "toastr";
import $ from "jquery";
import { API } from "../config/AppConstants";
import useSession from "../context/SessionContext";
import { LoginOutlined } from "@ant-design/icons";
import { message } from "antd";
import $sessionStore from "../store/session";

const Login = () => {
  message.config({
    top: -8,
    duration: 2,
  });
  const { user, setBusiness, setUser } = useSession();
  const [authenticating, setAuthenticating] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = async (values) => {
    setAuthenticating(true);
    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.LOGIN.AUTH,
      type: "GET",
      headers: {
        user_login: values.Username.toLowerCase(),
        user_pass: values.Password,
      },
      dataType: "json",
    })
      .then(async (res) => {
        let response = { ...res };
        response.job_type = "TruckItNow";
        setUser(response);
        $sessionStore.actions.setUser(response);
        redirectUser(res);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        if (jqXHR.status === 401) {
          message.error(
            <div>
              <b>Invalid credentials: </b>Please try again ...
            </div>,
            5
          );
        }
        if (jqXHR.status === 0) {
          Toastr.info(
            "Posssible Reasons: <br />  &nbsp;1. Invalid credentials with spl chars. <br/>     &nbsp;2. Internet Connectivity issues.",
            '<div class="mb-2">Request Failed</div>',
            { messageClass: "text-white p-0" }
          );
        }
        setAuthenticating(false);
      });
  };

  const redirectUser = async (res = user) => {
    let docs = await fetchDocs(res);
    let hh = new Date().getHours();
    const msg = [
      "Good ",
      hh < 12
        ? "Morning"
        : hh < 16
        ? "Afternoon"
        : hh > 16 && hh < 24
        ? "Evening"
        : "Day",
      " !",
    ];
    message.info(
      <div>
        <b>{msg}</b>
        <div> Welcome to TruckingEZ ...</div>
      </div>,
      2
    );
    await fetchBusinessProfile(res);
    setAuthenticating(false);
    if (docs.length > 1) {
      navigateBasedOnRole(res);
    } else {
      navigate("/upload");
    }
  };

  const navigateBasedOnRole = (res) => {
    const isDriver = res && res.role_id === "R013" && res.role_type === "R003";
    if (isDriver) {
      navigate("/driver/projects");
    } else {
      navigate("/projects");
    }
  };

  const fetchDocs = (user) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url:
          API.API_URL +
          API.API_VER +
          API.API_ENDPOINTS.DOCS.DOC_STORE +
          "?company_id=" +
          user.company_id +
          "&useGet=0",
        type: "GET",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        dataType: "json",
      })
        .then(async (res) => {
          resolve(res);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.status === 0) {
            Toastr.error("Failed to fetch the documents!");
          }
          resolve("failed to fetch the documents!");
        });
    });
  };

  const fetchBusinessProfile = (user) => {
    return new Promise((resolve, reject) => {
      console.log(
        API.API_URL +
          API.API_VER +
          API.API_ENDPOINTS.LOGIN.BUSINESS_PRFL +
          "?company_id=" +
          user.company_id
      );
      $.ajax({
        url:
          API.API_URL +
          API.API_VER +
          API.API_ENDPOINTS.LOGIN.BUSINESS_PRFL +
          "?company_id=" +
          user.company_id,
        type: "GET",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        dataType: "json",
      })
        .then(async (res) => {
          setBusiness(res);
          resolve(res);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.status === 0) {
            if (jqXHR.status === 0) {
              Toastr.error("Failed to fetch the business profile!");
            }
          }
          resolve("failed to fetch the business profile!");
        });
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Flex
      justify={"center"}
      style={{
        minHeight: "100vh",
        backgroundImage:
          "linear-gradient(rgba(0,0,0, 0.8), rgba(0,0,0, 0.8)), url(" +
          livePic +
          ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      <Flex justify={"center"} align={"center"}>
        <div
          className="auth-box auth-box-bg"
          style={{ minWidth: "350px", maxWidth: "350px", margin: "20px" }}
        >
          <div style={{ textAlign: "center" }}>
            <span>
              <img
                src={logo}
                alt="logo"
                style={{ maxWidth: "100%", padding: "25px" }}
              />
            </span>
          </div>
          {user && user.user_login ? (
            <div style={{ textAlign: "center", color: "white" }}>
              <br />
              <br />
              <span>You are already logged in ...</span>
              <br />
              <br />
              <Avatar
                style={{
                  cursor: "pointer",
                  backgroundColor: "black",
                  verticalAlign: "middle",
                }}
                size={{
                  xs: 48,
                  sm: 48,
                  md: 48,
                  lg: 64,
                  xl: 64,
                  xxl: 64,
                }}
                onClick={() => redirectUser()}
                icon={<LoginOutlined />}
              ></Avatar>
            </div>
          ) : (
            <Spin spinning={authenticating}>
              <Form
                form={form}
                layout="vertical"
                name="nest-messages"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Username"
                  name="Username"
                  rules={[
                    {
                      required: true,
                      message: `Username is required!`,
                    },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="Password"
                  rules={[
                    {
                      required: true,
                      message: `Password is required!`,
                    },
                  ]}
                >
                  <Input.Password type="password" placeholder="Password" />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 24 }}>
                  <Row justify={"space-between"}>
                    <Col span={24} align={"right"}>
                      <Space size={"small"}>
                        <Typography.Link
                          onClick={() => navigate("/forgot-password")}
                        >
                          Forgot Password &nbsp;
                        </Typography.Link>
                        <SubmitButton loading className="primary" form={form}>
                          Login
                        </SubmitButton>
                      </Space>
                    </Col>
                  </Row>
                </Form.Item>
                <Row style={{ marginTop: "100px" }}>
                  <Col span={24} align="center">
                    Don't have an account ?{" "}
                    <Typography.Link onClick={() => navigate("/register")}>
                      Signup
                    </Typography.Link>
                  </Col>
                </Row>
              </Form>
            </Spin>
          )}
        </div>
      </Flex>
    </Flex>
  );
};

export default Login;
