import { useNavigate } from "react-router-dom";
import React from "react";
import { theme } from "antd";
import useSession from "../context/SessionContext";
const { darkAlgorithm } = theme;

const AppConstants = () => {
  let navigate = useNavigate();

  const { user } = useSession();
  const roleId = user?.role_id;
  const truckerRoleId = "R003";
  const isTrucker = roleId === truckerRoleId;
  const mergedNavMenu = [
    {
      key: "K001-001",
      label: (
        <span
          className="ant-menu-nav-item"
          onClick={() => navigate("/projects")}
        >
          Available Projects
        </span>
      ),
      roles: ["R003"],
      path: "/projects",
    },
    {
      key: "K001-002",
      label: (
        <span
          className="ant-menu-nav-item"
          onClick={() => navigate("/projects")}
        >
          Posted Projects
        </span>
      ),
      roles: ["R001"],
      path: "/projects",
    },
    {
      key: "K001-003",
      label: (
        <span
          className="ant-menu-nav-item"
          onClick={() => navigate("/projects/bidded")}
        >
          Pending Bids
        </span>
      ),
      roles: ["R001", "R003"],
      path: "/projects/bidded",
    },
    {
      key: "K001-004",
      label: (
        <span
          className="ant-menu-nav-item"
          onClick={() => navigate("/load-projects")}
        >
          Contracted Projects
        </span>
      ),
      roles: ["R003"],
      path: "/load-projects",
    },
    {
      key: "K001-004",
      label: (
        <span
          className="ant-menu-nav-item"
          onClick={() => navigate("projects/contracted")}
        >
          Contracted
        </span>
      ),
      roles: ["R002"],
      path: "/projects/contracted",
    },
    {
      key: "K001-005",
      label: (
        <span
          className="ant-menu-nav-item"
          onClick={() => navigate("projects/dispatch")}
        >
          Dispatch
        </span>
      ),
      roles: [],
      path: "/projects/dispatch",
    },

    {
      key: "K001-007",
      label: (
        <span
          className="ant-menu-nav-item"
          onClick={() => navigate("projects/completed")}
        >
          Completed
        </span>
      ),
      roles: ["R001", "R003"],
      path: "/projects/completed",
    },
    {
      key: "K001-006",
      label: (
        <span
          className="ant-menu-nav-item"
          onClick={() => navigate("projects/billed")}
        >
          Billing
        </span>
      ),
      roles: ["R001", "R003"],
      path: "/projects/billed",
    },
  ];

  return {
    NAV_MENU: mergedNavMenu,
    NAV_SETTINGS: [
      {
        key: "R00000",
        label: (
          <span
            className="ant-menu-nav-item"
            onClick={() => navigate("/settings")}
          >
            Settings
          </span>
        ),
        roles: ["R001", "R002", "R003"],
      },
      {
        key: "R00002",
        label: (
          <span
            className="ant-menu-nav-item"
            onClick={() => navigate("/logout")}
          >
            Logout
          </span>
        ),
        roles: ["R001", "R002", "R003", "R013"],
      },
    ],
    BUSINESS_MENU_SETTINGS: [
      // {
      //     key: 'K006',
      //     label: <span className="ant-menu-nav-item" onClick={() => navigate('/business-profile')}>Business Profile</span>,
      //     roles: ['R001', 'R002', 'R003'],
      //     path: '/business-profile'
      // },
      {
        key: "K002",
        label: (
          <span
            className="ant-menu-nav-item"
            onClick={() => navigate("/mines")}
          >
            Mines
          </span>
        ),
        roles: ["R002"],
        path: "/mines",
      },
      {
        key: "K003",
        label: (
          <span
            className="ant-menu-nav-item"
            onClick={() => navigate("/fleet")}
          >
            Fleet
          </span>
        ),
        roles: ["R003"],
        path: "/fleet",
      },
      {
        key: "K004",
        label: (
          <span
            className="ant-menu-nav-item"
            onClick={() => navigate("/users")}
          >
            Users
          </span>
        ),
        roles: ["R001", "R002", "R003"],
        path: "/users",
      },
      {
        key: "K005",
        label: (
          <span className="ant-menu-nav-item" onClick={() => navigate("/docs")}>
            Documents
          </span>
        ),
        roles: ["R001", "R002", "R003"],
        path: "/docs",
      },
    ],
  };
};

export let THEME_DARK = {
  algorithm: darkAlgorithm,
  token: {
    fontFamily: "Archivo",
    colorPrimary: "#dc4446",
    colorInfo: "#2eb8c1",
    colorSuccess: "#82b44f",
    colorWarning: "#f2c357",
    colorLink: "#dc4446",
    colorBgBase: "#21272f",
    borderRadius: "4px",
  },
  components: {
    Typography: {
      colorLink: "#dc4446",
      colorLinkHover: "#e5e5e5",
    },
    Tabs: {
      inkBarColor: "white",
      itemColor: "white",
    },
    Menu: {
      colorBgContainer: "rgb(220, 68, 70)",
      colorText: "white",
      itemSelectedBg: "rgb(220, 68, 70)",
    },
    Button: {
      colorTextLightSolid: "#ffffff",
      defaultColor: "#e4e4e4",
      defaultShadow: "",
      primaryShadow: "",
      dangerShadow: "",
      colorPrimary: "#dc4446",
    },
    Table: {
      headerSplitColor: "#444444",
      headerBorderRadius: 4,
      headerSortHoverBg: "#4a5668",
      bodySortBg: "#323b47",
      headerBg: "#495668",
      headerFilterHoverBg: "#4a5668",
      headerSortActiveBg: "#4a5668",
      headerColor: "#ffffff",
      padding: 8,
      fontWeightStrong: 400,
    },
    Input: {
      colorBorder: "rgb(71, 84, 99)",
    },
    Card: {
      headerBg: "#495668",
      colorTextHeading: "#ffffff",
      fontWeightStrong: 400,
      fontSize: 14,
      fontSizeLG: 14,
      padding: 8,
    },
  },
};

export let THEME = {
  token: {
    borderColorBase: "red",
    fontWeightStrong: 400,
    colorPrimary: "#1358c2",
    colorInfo: "#1358c2",
    colorSuccess: "#69c50c",
    colorWarning: "#f2c357",
    colorLink: "#1358c2",
    borderRadius: 4,
  },
  components: {
    Tabs: {
      cardBg: "white",
      inkBarColor: "rgb(193, 159, 159)",
      itemActiveColor: "rgb(255, 255, 255)",
      itemColor: "rgb(0, 0, 0)",
      colorBorder: "rgb(245, 34, 45)",
      cardHeight: 30,
      cardPadding: "4px 16px",
      cardGutter: 3,
      borderRadius: 4,
      borderRadiusLG: 4,
      colorBgContainer: "rgb(47, 84, 235)",
      itemHoverColor: "rgb(250, 140, 22)",
      itemSelectedColor: "rgb(255, 255, 255)",
    },
    Layout: {
      bodyBg: "#f2f4f8",
    },
    Modal: {
      headerSplitColor: "rgba(255, 255, 255, 0.3)",
      headerBorderRadius: 4,
      headerBg: "rgb(19, 88, 194)",
      headerColor: "rgb(255, 255, 255)",
      headerPadding: "0.8rem 1rem",
      titleColor: "white",
      contentPadding: "0px 0px 15px 0px",
    },
    Table: {
      headerSplitColor: "rgba(255, 255, 255, 0.3)",
      headerBorderRadius: 4,
      headerBg: "rgb(19, 88, 194)",
      headerColor: "rgb(255, 255, 255)",
      headerFilterHoverBg: "rgba(255, 255, 255, 0.06)",
      headerSortActiveBg: "rgb(19, 88, 194)",
      headerSortHoverBg: "rgb(19, 88, 194)",
      bodySortBg: "rgb(247, 247, 247)",
      padding: 8,
    },
  },
};

export let API = {
  API_URL: "https://bihh81cf03.execute-api.us-east-1.amazonaws.com/",
  API_VER: "api-env-prod",
  API_KEY: "your-api-key",

  API_ENDPOINTS: {
    LOGIN: {
      AUTH: "/authenticate",
      SIGNUP: "/signup",
      GENERATE_CHALLENGE: "/user/generate-challenge",
      UPDATE_PASSWORD: "/user",
      BUSINESS_PRFL: "/business-profile",
      FILE_UPLOAD: "/file-upload",
      USERS: "/users",
    },
    ROLE: {
      ROLES: "/roles",
    },
    S3: {
      DOCS: "/documents",
    },
    MINE: {
      MINE: "/object/mine_info",
    },
    FLEET: {
      FLEET: "/object/fleet",
    },
    BUSINESS_PRFL: {
      BUSINESS_PRFL: "/object/business_profile",
    },
    DOCS: {
      DOC_STORE: "/object/doc_store",
    },
    COMMON_CONFIG: {
      COMMON_CONFIG: "/object/common_config",
    },
    CONTRACTOR: {
      JOBS: "/jobs",
    },
    TRUCKER: {
      BIDS: "/bids",
      HAULS: "/hauls",
    },
    DRIVER: {
      TRIPS: "/trips",
    },
    INBOX: {
      NOTIFICATIONS: "/object/notifications",
    },
    INVOICE: {
      INVOICEINFO: "/invoice",
      GETINVOICE: "/object/invoices",
    },
  },
};

export default AppConstants;
