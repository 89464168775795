import { useQuery } from "@tanstack/react-query";
import { Queries } from ".";
import { fetchDrivers } from "../services/users";

const useDrivers = () => {
  return useQuery({
    queryKey: [Queries.drivers],
    queryFn: fetchDrivers,
  });
};

export default useDrivers;
