import { API } from "../../config/AppConstants";
import $sessionStore from "../../store/session";
import { apiHelper } from "../instance";

export const fetchTrucks = async ({ useGet = 0 }) => {
  const { company_id } = $sessionStore.actions.getUser();
  const { data } = await apiHelper.get(API.API_ENDPOINTS.FLEET.FLEET, {
    params: {
      company_id,
      useGet,
    },
  });
  return data;
};
