function get(key) {
    let obj = sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : null;
    switch (key) {
        case 'user':
            if (obj && obj.token_expiry) {
                if (obj.token_expiry < new Date().valueOf()) {
                    obj = null;
                } else {
                    obj.token_expiry = (new Date().valueOf() + (30 * 60 * 1000));
                }
                set(key, obj);
            }
            break;

        default:
            break;
    }

    return obj;
};

function set(key, obj) {
    switch (key) {
        case 'user':
            if (obj !== null) {
                obj.token_expiry = (new Date().valueOf() + (30 * 60 * 1000));
                sessionStorage.setItem(key, JSON.stringify(obj));
            } else {
                sessionStorage.removeItem(key);
                sessionStorage.removeItem('business');
            }
            return;

        default:
            if (obj !== null) {
                sessionStorage.setItem(key, JSON.stringify(obj));
            } else {
                sessionStorage.removeItem(key);
            }
            break;
    }
};

module.exports = { set, get };