import { CaretDownOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button } from "antd";

const TripInfo = ({ day, date, tonsCompleted, status, children }) => {
  return (
    <div className="mt-4 space-y-10">
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          <CaretDownOutlined />
          <h1 className="font-bold text-lg">Day {day}</h1>
          <span className="font-normal">
            {moment(date).format("MM-DD-YYYY")}
          </span>
          <h1 className="font-bold text-lg">{tonsCompleted} Tons</h1>
          <h1 className="text-lg text-[#13B606]">{status}</h1>
        </div>

        <Button
          size="large"
          onClick={() => {}}
          style={{
            borderRadius: "5px",
            backgroundColor: "#6F55FF",
            color: "#FFFFFF",
          }}
        >
          Invoice
        </Button>
      </div>

      <div className="">
        <div className="grid grid-cols-2 gap-4">{children}</div>
      </div>
    </div>
  );
};

export default TripInfo;
