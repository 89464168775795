import { Progress } from "antd";

const ProjectProgressInfo = ({ percent = 0, total = 0, completed = 0 }) => {
  return (
    <div className="space-y-4">
      <h1 className="text-xl font-semibold">Tons Transported</h1>
      <Progress percent={percent} size={[null, 20]} showInfo={false} />
      <div className="flex justify-between">
        <div className="flex flex-col">
          <span className="text-xl font-semibold">{percent}%</span>
          <span className="text-md">{completed} Tons</span>
        </div>

        <div className="flex flex-col">
          <span className="text-xl font-semibold">100%</span>
          <span className="text-md">{total} Tons</span>
        </div>
      </div>
    </div>
  );
};

export default ProjectProgressInfo;
