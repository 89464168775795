import React, { useState } from 'react';
import livePic from "../assets/img/construction.png";
import { Flex, Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import logo from '../assets/img/logo_transparent_.png';
import SubmitButton from "../components/Button";
import Toastr from 'toastr';
import $ from 'jquery';
import { API } from '../config/AppConstants';

const ForgotPassword = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [userName, setUserName] = useState('');

    const onsubmit = (values) => {

        let data = {
            "form_input": {
                "user_login": values.Username,
            }
        };

        let confirmData = {
            "form_input": {
                "user_login": userName,
                "challenge": values.Challenge,
                "user_pass": values.New_password
            }
        }
        let api_url = isValidEmail ? API.API_URL + API.API_VER + API.API_ENDPOINTS.LOGIN.UPDATE_PASSWORD : API.API_URL + API.API_VER + API.API_ENDPOINTS.LOGIN.GENERATE_CHALLENGE;
        $.ajax(
            {
                url: api_url,
                type: 'PUT',
                dataType: "json",
                data: !isValidEmail ? JSON.stringify(data) : JSON.stringify(confirmData)
            }
        ).then(
            res => {
                if (res) {
                    if (isValidEmail) {
                        Toastr.success('Password updated Successfully!');
                        navigate('/');
                    }
                    else {
                        Toastr.success('Verification code sent Successfully!');
                        setIsValidEmail(true);
                    }
                }
            },
            (err) => {
                let error_code = err.responseJSON.error.code;
                switch (error_code) {

                    case 'INVALID_USER':
                        Toastr.error('User account doesn\'t exist!');
                        break;

                    case 'SAME_NEW_PWD':
                        Toastr.error('Old and new password can\'t be same!');
                        break;

                    case 'INVALID_CHALLENGE_CODE':
                        Toastr.error('Invalid Verification Code!');
                        break;

                    default:
                        Toastr.error('Verification code expired!');
                        setIsValidEmail(false);
                        break;
                }
            }
        )
    }

    return (
        <Flex justify={'center'} style={{
            minHeight: '100vh', backgroundImage: 'linear-gradient(rgba(0,0,0, 0.8), rgba(0,0,0, 0.8)), url(' + livePic + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'
        }}>
            <Flex justify={'center'} align={'center'} >
                <div className="auth-box auth-box-bg">
                    <div style={{ textAlign: 'center' }
                    } >
                        <span>
                            <img className="pb-1 " src={logo} alt="logo" style={{ width: "250px" }} />
                        </span>
                    </div>
                    <Title type="primary" level={4} style={{ textAlign: 'center', marginBottom: '30px', fontSize: '17px' }} >Forgot Password</Title>
                    <div style={{ textAlign: 'center', marginBottom: '30px', color: 'white', width: window.innerWidth <= 600 ? "18rem" : "20rem" }}>
                        {!isValidEmail ?
                            "Please enter your username, and we will send the Verification code to the admin to reset your password."
                            :
                            "The Verification code has been sent to the admin account. Please contact the admin for the Verification code."}
                    </div>

                    <Form
                        form={form}
                        onFinish={onsubmit}
                        layout='vertical'
                        name="nest-messages"
                        autoComplete="off" >
                        {!isValidEmail && <div>
                            <Form.Item
                                label='Username'
                                name='Username'
                                rules={[
                                    { required: true, message: `Username is required!`, }
                                ]}
                            >
                                <Input placeholder="Username" onChange={(e) => setUserName(e.target.value)} />
                            </Form.Item>
                            <div className="steps-action align-right">
                                <SubmitButton type="primary" form={form}>
                                    Submit
                                </SubmitButton>
                                <Button style={{ margin: '0 8px' }} onClick={() => navigate('/')}>
                                    Cancel
                                </Button>
                            </div>
                        </div>}
                        {isValidEmail && <div>
                            <Form.Item
                                label='Verification code'
                                name='Challenge'
                                rules={[
                                    { required: true, message: `Verification code is required!` }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={'New Password'}
                                name={'New_password'}
                                rules={[
                                    { required: true, message: `Password is required!` }
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <div className="steps-action align-right">
                                <SubmitButton type="primary" form={form}>
                                    confirm
                                </SubmitButton>
                            </div>
                        </div>}
                    </Form>
                </div>
            </Flex >
        </Flex >

    );
};

export default ForgotPassword;


