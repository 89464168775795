import { API } from "../../config/AppConstants";
import $sessionStore from "../../store/session";
import { apiHelper } from "../instance";

export const fetchDrivers = async ({ useGet = 0 }) => {
  const { company_id } = $sessionStore.actions.getUser();
  const { data } = await apiHelper.get(API.API_ENDPOINTS.LOGIN.USERS, {
    params: {
      company_id,
      useGet,
      role_id: "R013",
      role_type: "R003",
    },
  });
  return data;
};

export const fetchQuarries = async ({ useGet = 0 }) => {
  const { data } = await apiHelper.get(API.API_ENDPOINTS.LOGIN.USERS, {
    params: {
      useGet,
      role_id: "R002",
    },
  });
  return data;
};
