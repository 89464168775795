import React from "react";
import Bids from "./roles/contractor/bids";
import useSession from "./context/SessionContext";

const Dashboard = () => {
  const { user } = useSession();
  return user && user.role_id === "R001" ? <Bids /> : <div></div>;
};

export default Dashboard;
