import { Component } from "react";

// TODO: Implement Fallback UI for Production

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
      errorStack: "",
    };
  }

  static getDerivedStateFromError(error, errorInfo) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);

    this.setState({
      errorMessage: error.message,
      errorStack: errorInfo.componentStack || undefined,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          {true ? (
            <div className="grid h-screen place-items-center">
              <div className="card grid gap-4">
                <h1 className="text-red-500 text-center">Error Status</h1>
                <h2>{this.state.errorMessage}</h2>
                <pre>Component Stack: {this.state.errorStack}</pre>
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
