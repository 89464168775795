import { useMutation } from "@tanstack/react-query";
import { Mutations } from ".";
import { startTrip } from "../services/drivers";

const useCreateTrip = ({ onSuccess }) => {
  return useMutation({
    mutationFn: startTrip,
    mutationKey: [Mutations.startDriverTrip],
    onSuccess,
  });
};

export default useCreateTrip;
