const { API } = require("./config/AppConstants");
const $ = require("jquery");

const getConfig = async (config_id = "JOB_STATES") => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.COMMON_CONFIG.COMMON_CONFIG +
        "?config_id=" +
        config_id,
      type: "GET",
      dataType: "json",
    })
      .then(async (res) => {
        resolve(res && res.Item && res.Item.config ? res.Item.config : null);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        resolve([]);
      });
  });
};

const formatter = (format_type) => {
  switch (format_type) {
    case "CAPITALIZE":
      return (x) => x.charAt(0).toUpperCase() + x.substring(1, x.length);

    case "UPPERCASE":
      return (x) => x.toUpperCase();

    case "LOWERCASE":
      return (x) => x.toLowerCase();

    default:
      return (x) => x;
  }
};

module.exports = { formatter, getConfig };
