import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import SubmitButton from "../../components/Button";
import TextArea from "antd/es/input/TextArea";
import { FaDollarSign } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import MapPicker from "../../components/MapPicker";
import useSession from "../../context/SessionContext";
import { API } from "../../config/AppConstants";
import $ from "jquery";
import moment from "moment";
import Toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import UTIL from "./../../Util";

const Bids = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const params = useParams();
  let { business, user } = useSession();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [bidClosingDate, setBidClosingDate] = useState("");
  const [date, setdDate] = useState(null);
  const [haulingVal, setHaulingVal] = useState("Hourly");
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const tagColors = {
    Pending: "#e89a3c",
    InProgress: "#e774bb",
    Dispatch: "rgb(201 154 84)",
    Billed: "gold",
    Contracted: "#2db1a8",
    Declined: "#9a3615",
    ContractorDeclined: "#9a3615",
    Completed: "#4c9c33",
    "Invoice Generated": "#008080",
  };
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [gps, setGps] = useState("");
  const [activePage, setactivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [addMaterial, setAddMaterial] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [material, setMaterial] = useState([
    "Sand",
    "Modified Subbase",
    "Special Backfill",
    "Rip Rap",
    "Pea Gravel",
    "Clean Stone",
    "Dirt",
    "Broken Concrete/Ashalt",
    "Crushed Concrete",
  ]);

  const bids_columns = [
    {
      title: "Business Name",
      dataIndex: "business_name",
      render: (business_name, record, i) => {
        return <span>{business_name}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Bid Amount",
      dataIndex: "bid_amount",
      render: (bid_amount, record, i) => {
        return (
          <span>
            {"$ " + bid_amount} /{" "}
            {record.hauling_type === "Hourly" ? "Hr" : record.hauling_type}
          </span>
        );
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Actions",
      render: (action, record) => {
        let content;
        switch (record.contractor_choice) {
          case "Contracted":
          case "Declined":
            content = (
              <Tag color={tagColors[record.contractor_choice]}>
                {record.contractor_choice}
              </Tag>
            );
            break;

          case "Pending":
            content = (
              <div>
                <Button
                  onClick={() => handleAccept(record, "declined")}
                  size="small"
                >
                  Decline
                </Button>{" "}
                <Button
                  onClick={() => handleAccept(record, "accept")}
                  style={{ marginLeft: "10px" }}
                  size="small"
                  type="primary"
                >
                  Accept
                </Button>
              </div>
            );
            break;

          default:
            content = (
              <Tag color={tagColors[record.contractor_choice]}>
                {record.contractor_choice}
              </Tag>
            );
            break;
        }
        return <div>{content}</div>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
  ];

  const jobs_columns = [
    {
      title: "Project Name",
      dataIndex: "project_name",
      render: (job_type, record, i) => {
        return (
          <Badge color={"white"} count={record.bids ? record.bids.length : 0}>
            <span> {job_type} </span>&nbsp;&nbsp;&nbsp;
          </Badge>
        );
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
      fixed: "left",
    },
    {
      title: "Job Status",
      dataIndex: "job_status",
      render: (job_status, record, i) => {
        console.log(job_status);
        return <Tag color={tagColors[job_status]}>{job_status}</Tag>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (trucking_company, record, i) => {
        let address = record.address
          ? record.address +
            ", " +
            record.city +
            ", " +
            record.state +
            ", " +
            record.zipcode
          : "-";
        return (
          <Typography.Text>
            {address.length > 25 ? (
              <Tooltip title={address}>
                {address.substring(0, 25) + "..."}
              </Tooltip>
            ) : (
              address
            )}
          </Typography.Text>
        );
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Material",
      dataIndex: "material",
      render: (material, record, i) => {
        return (
          <span style={{ display: "block", width: "120px" }}>{material}</span>
        );
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Job Start Date",
      dataIndex: "job_date_ts",
      render: (job_date_ts, record, i) => {
        return (
          <span style={{ display: "block", width: "150px" }}>
            {new Date(job_date_ts).toLocaleString()}{" "}
          </span>
        );
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: (
        <span style={{ display: "block", width: "90px" }}>Hauling Type</span>
      ),
      dataIndex: "hauling_type",
      render: (hauling_type, record, i) => {
        return <span>{hauling_type}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Est. Qty",
      dataIndex: "quantity",
      render: (budget, record) => {
        return <span>{record.quantity || record.hourly_minimum}</span>;
      },
      width: "50px",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Total Est. Amount",
      dataIndex: "budget",
      render: (budget, record) => {
        return (
          <span>
            $ {(record.quantity || record.hourly_minimum) * record.budget}
          </span>
        );
      },
      width: "50px",
    },
    {
      title: "Modified",
      dataIndex: "modified_ts",
      hidden: true,
      render: (modified_ts, record, i) => modified_ts,
      defaultSortOrder: "descend",
      sorter: (a, b) => b.modified_ts - a.modified_ts,
    },
    {
      title: "action",
      dataIndex: "job_status",
      hidden: params.job_status !== "completed",
      render: (job_status, record) => {
        return job_status === "Invoice Generated" ? (
          <Button
            size="small"
            type="primary"
            onClick={() => navigate("/invoice/" + record.invoice.invoice_id)}
          >
            View Invoice
          </Button>
        ) : (
          "-"
        );
      },
    },
  ];

  const onNameChange = (event) => {
    setAddMaterial(event.target.value);
  };

  const addItem = () => {
    if (addMaterial && !material.includes(addMaterial)) {
      const newMaterial = [...material, addMaterial];
      setMaterial(newMaterial);
      form.setFieldsValue({ material: addMaterial });
      setAddMaterial("");
      setDropdownVisible(false); // Close the dropdown
    }
  };

  const onChange = (date, dateStr) => {
    const timestamp = date && date.toDate().getTime();
    setdDate(timestamp);
    var timehrs = timestamp - 7200000;
    setBidClosingDate(timehrs);
    form.setFieldsValue({ bid_closing_date_ts: moment(timehrs) });
  };

  const handleAccept = (rec, action) => {
    let data = {
      job_id: rec.job_id,
      bid_id: rec.bid_id,
      company_id: business && business.profile.company_id,
      action: action,
    };

    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.CONTRACTOR.JOBS,
      type: "PUT",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          props.fetchJobs();
          Toastr.success("Status Updated Successfully!");
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
      }
    );
  };

  const validateZipCode = (_, value) => {
    let zipPattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (value && !zipPattern.test(value)) {
      return Promise.reject("Enter Valid Zip Code");
    }
    return Promise.resolve();
  };

  const hauling = [
    { label: "Hourly", value: "Hourly" },
    { label: "Ton", value: "Ton" },
    { label: "Yard", value: "Yard" },
  ];

  const handleFinish = (values) => {
    let formatMethod = UTIL.formatter("CAPITALIZE");
    let jobType = formatMethod(user.job_type);
    console.log(jobType, "jobType");

    let data = {
      form_input: {
        company_id: business.profile.company_id,
        project_name: values.project_name,
        business_name: business.profile.business_name,
        job_type: jobType,
        job_date_ts: date,
        address: values.address,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        gps: values.gps && {
          latitude: values.gps.split(",")[0],
          longitude: values.gps.split(",")[1],
        },
        process_type: values.process_type,
        material: values.material,
        hauling_type: values.hauling_type,
        special_instructions: values.special_instructions,
        budget: values.budget,
        hourly_minimum: values.minimum,
        quantity: values.quantity,
        bid_closing_date_ts: bidClosingDate,
      },
    };
    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.CONTRACTOR.JOBS,
      type: "POST",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify(data),
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    }).then(
      (res) => {
        if (res) {
          Toastr.success("Job Created successfully!");
          setModalVisible(false);
          props.fetchJobs();
          form.resetFields();
          setAddress("");
          setCity("");
          setState("");
          setZipcode("");
          setGps("");
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
      }
    );
  };

  const handleLocationCoordinated = (value) => {
    let lat_lng_val =
      value && value.lat.toFixed(2) + "," + value.lng.toFixed(2);
    form.setFieldsValue({ gps: lat_lng_val });
    setGps(lat_lng_val);
    setMapModalVisible(false);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return current && current.toDate() < today;
  };
  return (
    <React.Fragment>
      {Object.keys(params).length === 0 && (
        <Row>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button type="primary" onClick={() => setModalVisible(true)}>
              Create New job
            </Button>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: "15px" }}>
        <Col span={24}>
          <div className="ant-table-custom-wrapper">
            <Table
              columns={jobs_columns}
              dataSource={props.job}
              rowKey={(record) => record.job_id}
              pagination={
                props.job &&
                props.job.length > 10 && {
                  showSizeChanger: true, // Enable page size options
                  pageSizeOptions: ["10", "15", "20"],
                  pageSize: pageSize,
                  current: activePage,
                  total: props && props.job && props.job.length,
                  onChange: (activePage, pageSize) => {
                    setactivePage(activePage);
                    setPageSize(pageSize);
                  },
                }
              }
              loading={props.loading}
              expandable={{
                expandedRowRender: (record) => {
                  for (const rec of record.bids) {
                    rec.job_id = record.job_id;
                    rec.hauling_type = record.hauling_type;
                  }
                  return (
                    <div>
                      <div style={{ padding: "0 1em 2em 2em" }}>
                        <Table
                          rowKey={(record) => record.bid_id}
                          columns={bids_columns}
                          dataSource={record.bids}
                          pagination={false}
                        />
                      </div>
                    </div>
                  );
                },
                rowExpandable: (record) =>
                  record.bids ? record.bids.length > 0 : false,
              }}
            />
          </div>
        </Col>
      </Row>
      <Modal
        title={"Create New Job"}
        open={modalVisible}
        maskClosable={false}
        width={600}
        onCancel={() => {
          setModalVisible(false);
        }}
        style={{ top: 60 }}
        destroyOnClose={true}
        footer={false}
        zIndex={1}
      >
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            maxWidth: "100%",
          }}
        >
          <Form
            form={form}
            onFinish={handleFinish}
            layout="vertical"
            name="nest-messages"
            labelCol={{ span: 18 }}
            wrapperCol={{ span: 22 }}
            style={{ maxWidth: 700, margin: "15px 30px" }}
            initialValues={{ hauling_type: haulingVal }}
            autoComplete="off"
          >
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name={"project_name"}
                  label={"Project Name"}
                  rules={[
                    { required: true, message: `Project Name is required!` },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name={"date"}
                  label={"Date"}
                  rules={[{ required: true, message: `Date is required!` }]}
                >
                  <DatePicker
                    showTime={{ format: "HH:mm", use12Hours: true }}
                    disabledDate={disabledDate}
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name={"address"} label={"Address"}>
                  <TextArea
                    rows={1}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name={"city"} label={"City"}>
                  <Input
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name={"state"} label={"State"}>
                  <Input
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name={"zipcode"} label={"Zip Code"}>
                  <Input
                    value={zipcode}
                    onChange={(e) => setZipcode(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name={"gps"} label={"GPS"}>
                  <Input
                    suffix={
                      <FaLocationDot
                        style={{ cursor: "pointer", color: "red" }}
                        value={gps}
                        onClick={() => setMapModalVisible(true)}
                      />
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name={"material"}
                  label={"Material"}
                  rules={[{ required: true, message: `Material is required!` }]}
                >
                  <Select
                    options={material.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(selected) => {
                      form.setFieldsValue({ material: selected });
                    }}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder="Please enter item"
                            value={addMaterial}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <Button type="primary" size="small" onClick={addItem}>
                            Add Other
                          </Button>
                        </Space>
                      </div>
                    )}
                    onDropdownVisibleChange={(open) => setDropdownVisible(open)}
                    open={dropdownVisible}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name={"hauling_type"}
                  label={"Hauling Type"}
                  rules={[
                    { required: true, message: `Hauling Type is required!` },
                  ]}
                >
                  <Select
                    options={hauling}
                    values={haulingVal}
                    onChange={(selected) => {
                      setHaulingVal(selected);
                    }}
                  />
                </Form.Item>
              </Col>
              {haulingVal && haulingVal === "Hourly" ? (
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name={"minimum"}
                    label={"Minimum"}
                    rules={[
                      { required: true, message: `Minimum is required!` },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name={"quantity"}
                    label={"Quantity"}
                    rules={[
                      { required: true, message: `Quantity is required!` },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name={"budget"} label={"Budget"}>
                  <InputNumber
                    addonBefore={<FaDollarSign />}
                    addonAfter={
                      haulingVal === "Hourly" ? "/Hours" : "/" + haulingVal
                    }
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name={"bid_closing_date_ts"}
                  label={"Bid Closing Date"}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    showTime={{ format: "HH:mm", use12Hours: true }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name={"process_type"}
                  label={"Job Type"}
                  rules={[{ required: true, message: `Job Type is required!` }]}
                >
                  <Radio.Group value="import">
                    <Radio value="import">Import</Radio>
                    <Radio value="export">Export</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name={"special_instructions"}
                  label={"Additional Information"}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            </Row>

            <Col
              style={{
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "15px",
                marginRight: "18px",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
              <SubmitButton form={form}>Submit Job</SubmitButton>
            </Col>
          </Form>
        </div>
      </Modal>

      <Modal
        title={"Select Location"}
        open={mapModalVisible}
        maskClosable={false}
        width={800}
        onCancel={() => {
          setMapModalVisible(false);
          form.resetFields();
        }}
        style={{ top: 60 }}
        footer={true}
        zIndex={10}
      >
        <MapPicker setLocation={handleLocationCoordinated} />
      </Modal>
    </React.Fragment>
  );
};

export default Bids;
