import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Upload } from "antd";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import Toastr from "toastr";
import { API } from "../config/AppConstants";
import useSession from "../context/SessionContext";

const UploadDocuments = () => {
  const { user, business } = useSession();
  let navigate = useNavigate();

  const uploadDocument = ({ file, onSuccess, onError }) => {
    return new Promise((resolve, reject) => {
      let data = {
        form_input: {
          file_name: file.name,
          mime_type: file.type,
          file_extn: file.name.split(".").pop(),
          company_id: user.company_id,
          doc_type: file.doc_type,
        },
      };
      $.ajax({
        url: API.API_URL + API.API_VER + API.API_ENDPOINTS.LOGIN.FILE_UPLOAD,
        type: "POST",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        data: JSON.stringify(data),
      }).then(
        async (res) => {
          resolve(res);
          let blobData = new Blob([file], { type: file.type });
          const result = await fetch(res.url, {
            method: "PUT",
            body: blobData,
          });

          let docs_info = {
            file_name: res.file_name,
            original_file_name: file.name,
            company_id: business.profile.company_id,
            doc_type: file.doc_type,
          };
          await updateDocs(docs_info);
          if (result.status === 200) {
            onSuccess(res.data);
          } else {
            Toastr.success("Failed to upload the file!");
          }
        },
        (err) => {
          resolve("Something went wrrong");
          onError(err);
        }
      );
    });
  };

  const updateDocs = (fileData) => {
    return new Promise((resolve, reject) => {
      let data = { form_input: { ...fileData } };
      $.ajax({
        url: API.API_URL + API.API_VER + API.API_ENDPOINTS.DOCS.DOC_STORE,
        type: "PUT",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        data: JSON.stringify(data),
      }).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          resolve("Something went wrrong");
        }
      );
    });
  };

  const fetchDocs = () => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url:
          API.API_URL +
          API.API_VER +
          API.API_ENDPOINTS.DOCS.DOC_STORE +
          "?company_id=" +
          user.company_id +
          "&useGet=0",
        type: "GET",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        dataType: "json",
      })
        .then(async (res) => {
          resolve(res);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.status === 0) {
            Toastr.error("Failed to fetch the documents!");
          }
          resolve("failed to fetch the documents!");
        });
    });
  };

  return (
    <div>
      <div style={{ padding: "20px" }}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={16} lg={24} xl={24} xxl={24}>
            <div style={{ textAlign: "center" }}>
              <h2>Upload Documents</h2>
              <p>
                Upload the required documents before accessing the application
              </p>
            </div>

            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100vw",
              }}
            >
              <Upload
                customRequest={uploadDocument}
                beforeUpload={(file) => {
                  const isJpgOrPng =
                    file.type === "image/jpeg" ||
                    file.type === "image/png" ||
                    file.type === "application/pdf";
                  if (!isJpgOrPng) {
                    Toastr.error("Supported file types are : PDF, JPEG, PNG");
                    return Upload.LIST_IGNORE;
                  }
                  let w9Document = file;
                  w9Document.file_name = "doc_w9";
                  w9Document.doc_type = "DOC_W9";
                  return true;
                }}
                onChange={async (info) => {
                  if (info.file.status === "done") {
                    Toastr.success(
                      `${info.file.name} file uploaded successfully`
                    );
                    let docs = await fetchDocs();
                    if (docs.length > 1) {
                      navigate("/projects");
                    }
                  } else if (info.file.status === "error") {
                    Toastr.error(`${info.file.name} file upload failed.`);
                  }
                }}
                style={{ textAlign: "center", marginBottom: "20px" }}
                maxCount={1}
              >
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    textAlign: "center",
                    width: "300px",
                    borderRadius: "13px",
                  }}
                >
                  Upload W9 Document
                </Button>
              </Upload>
            </Col>

            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100vw",
                marginTop: "25px",
              }}
            >
              <Upload
                customRequest={uploadDocument}
                beforeUpload={(file) => {
                  const isJpgOrPng =
                    file.type === "image/jpeg" ||
                    file.type === "image/png" ||
                    file.type === "application/pdf";
                  if (!isJpgOrPng) {
                    Toastr.error("Supported file types are : PDF, JPEG, PNG");
                    return Upload.LIST_IGNORE;
                  }
                  let current_certificate = file;
                  current_certificate.file_name = "doc_insurance";
                  current_certificate.doc_type = "DOC_INSURANCE";
                  return true;
                }}
                onChange={async (info) => {
                  if (info.file.status === "done") {
                    Toastr.success(
                      `${info.file.name} file uploaded successfully`
                    );
                    let docs = await fetchDocs();
                    if (docs.length > 1) {
                      navigate("/projects");
                    }
                  } else if (info.file.status === "error") {
                    Toastr.error(`${info.file.name} file upload failed.`);
                  }
                }}
                style={{ textAlign: "center", marginBottom: "20px" }}
                maxCount={1}
              >
                <Button
                  icon={<UploadOutlined />}
                  style={{
                    textAlign: "center",
                    width: "300px",
                    borderRadius: "13px",
                  }}
                >
                  Upload Current Insurance Certificate
                </Button>
              </Upload>
            </Col>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default UploadDocuments;
