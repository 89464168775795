import { Calendar } from "antd";
import { ErrorMessage, useFormikContext } from "formik";
import React from "react";
import FormikErrorMessage from "../../primitives/FormikErrorMessage";

const SelectStartDateFormPhase = () => {
  const form = useFormikContext();
  return (
    <React.Fragment>
      <Calendar
        disabledDate={(date) => {
          if (date.endOf("d").valueOf() < Date.now()) {
            return true;
          }
          return false;
        }}
        fullscreen={false}
        value={form.values.date}
        onChange={(value) => {
          form.setFieldValue("date", value);
        }}
      />
      <FormikErrorMessage name={"date"} />
    </React.Fragment>
  );
};

export default SelectStartDateFormPhase;
