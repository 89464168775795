import React from "react";
import { Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
import useJobs from "../../api/queries/useJobs";
import Loader from "../../components/primitives/Loader";
import StatusEnum from "../../enums/status";
import moment from "moment";
import { capitalise } from "../../utils/string";

const LoadProjects = () => {
  const navigate = useNavigate();
  const getColorByStatus = (status) => {
    const statusColors = {
      Hauling: "#00B336",
      Assigned: "#6F55FF",
      Unassigned: "#DA6944",
    };

    return statusColors[status] || "#000000";
  };

  const { data: jobs, isLoading } = useJobs({
    assignedTo: "trucker",
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "job",
      key: "job",
      render: (job) => job.project_name,
    },
    {
      title: "Location",
      dataIndex: "job",
      key: "job",
      render: (job) => job.address,
    },
    {
      title: "Start Date / Time",
      dataIndex: "job",
      key: "job",
      render: (job) => moment(Date(job.job_date_ts)).format("DD/MM/YYYY"),
    },
    {
      title: "Material",
      dataIndex: "job",
      key: "job",
      render: (job) => job.material,
    },
    {
      title: "Qty",
      dataIndex: "job",
      key: "job",
      render: (job) => job.quantity,
    },
    {
      title: "Remaining",
      dataIndex: "job",
      key: "job",
      render: (job) => job.quantity - (job.tons_completed ?? 0),
    },
    {
      title: "% Complete",
      dataIndex: "job",
      key: "job",
      render: (job) => {
        const totalQuantity = job.quantity;
        const completedQuantity = job.tons_completed ?? 0;
        const percentComplete = Math.round(
          (completedQuantity / totalQuantity) * 100
        );
        return <div style={{ color: "#00B336" }}>{percentComplete}%</div>;
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "job",
      render: (job) => {
        const haulingStatus = capitalise(job.haul_status ?? "Unassigned");

        return (
          <Button
            onClick={() => {
              switch (haulingStatus) {
                default:
                  navigate(`/manage/projects/${job.job_id}`);
              }
            }}
            style={{
              borderRadius: "20px",
              backgroundColor: getColorByStatus(haulingStatus),
              color: "#FFFFFF",
            }}
          >
            {haulingStatus}
          </Button>
        );
      },
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Table columns={columns} dataSource={jobs} />
    </div>
  );
};

export default LoadProjects;
