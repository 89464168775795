import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { createRef, useEffect, useState } from "react";
import CameraIcon from "../../../icons/camera";
import { ErrorMessage, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import useHauls from "../../../../api/queries/useHauls";
import useUpdateTrip from "../../../../api/mutations/useUpdateTrip";
import Loader from "../../../primitives/Loader";
import HaulAssignmentHelper from "../../../../helpers/haulAssignmentHelper";
import AssignmentHelper from "../../../../helpers/assignmentHelper";
import { TripStatus } from "../../../../enums/status";

const DestinationPhotoPickerFormPhase = () => {
  const form = useFormikContext();
  const inputKeyName = "photoAtDestination";
  const selectedFile = form.values.photoAtDestination;
  const ref = createRef();

  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      // Clean up the object URL when the component is unmounted or when the file changes
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const { assignment_id } = useParams();

  const { data: hauls, isLoading: isHaulsLoading } = useHauls({
    assignment_id,
  });

  const { mutate: updateTripStatus, isPending: isUpdatePending } =
    useUpdateTrip({
      onSuccess: () => {
        form.submitForm();
      },
    });

  if (isHaulsLoading) {
    return <Loader />;
  }

  const haulsData = hauls[0];

  const haulHelper = new HaulAssignmentHelper(haulsData);

  const assignment = haulHelper.assignmentById(assignment_id);

  const assignmentHelper = new AssignmentHelper(assignment);

  const { _id } = assignmentHelper.getTripByAssigmentId(assignment_id);

  const handleSubmit = () => {
    const payload = {
      trip_id: _id,
      status: TripStatus.COMPLETED,
    };
    updateTripStatus(payload);
  };

  const props = {
    showUploadList: false,
    beforeUpload(file) {
      form.setFieldValue(inputKeyName, file);
      return false;
    },
  };

  const removedPhoto = () => {
    form.setFieldValue(inputKeyName, null);
    setPreview(null);
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center gap-8">
        <div className="border-[1px] border-dashed border-[#0BA98D] w-96 h-96 rounded-xl relative">
          {preview != null ? (
            <div className="relative">
              <img src={preview} className="w-96 h-96 rounded-xl p-2" alt="" />
              <div
                onClick={removedPhoto}
                className="absolute top-4 right-4 w-6 h-6 rounded-[50%] flex justify-center items-center bg-[rgb(151,29,29)] cursor-pointer"
              >
                <CloseOutlined />
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                ref.current.click();
              }}
              className="flex justify-center items-center h-full cursor-pointer"
            >
              <div className="flex flex-col items-center w-28 gap-2">
                <CameraIcon />
                <h2 className="text-[#0BA98D] text-center font-bold">
                  Take a picture of the full truck
                </h2>
              </div>
            </div>
          )}

          <Upload {...props}>
            <Button ref={ref} hidden icon={<UploadOutlined />} />
          </Upload>
        </div>
        <ErrorMessage name={inputKeyName} />
        <Button onClick={handleSubmit} loading={isUpdatePending}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default DestinationPhotoPickerFormPhase;
