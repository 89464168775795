import { API } from "../../config/AppConstants";
import { apiHelper } from "../instance";

export const fetchDriverJobs = async ({
  date,
  method = "getDriverJobAssignments",
}) => {
  const { data } = await apiHelper.get(API.API_ENDPOINTS.TRUCKER.HAULS, {
    params: {
      date,
      method,
    },
  });
  return data;
};

export const startTrip = async (payload) => {
  const { data } = await apiHelper.post(
    API.API_ENDPOINTS.DRIVER.TRIPS,
    payload
  );
  return data;
};

export const updateTripStatus = async (payload) => {
  const { data } = await apiHelper.put(API.API_ENDPOINTS.DRIVER.TRIPS, payload);
  return data;
};
