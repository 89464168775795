import { useQuery } from "@tanstack/react-query";
import { StatusEnum } from "../../enums/status";
import { Queries } from ".";
import { fetchJobs } from "../services/jobs";

const useJobs = ({ job_status = StatusEnum.CONTRACTED, assignedTo } = {}) => {
  return useQuery({
    queryKey: [Queries.jobs, job_status],
    queryFn: () => fetchJobs({ job_status, assignedTo }),
  });
};

export default useJobs;
