import { Button } from "antd";

import dummyMap from "../../assets/img/dummy-big.png";
import { useNavigate, useParams } from "react-router-dom";
import DriverTripDropdown from "../../components/primitives/DriverTripDropdown";
import ProjectProgressInfo from "../../components/primitives/ProjectProgressInfo";
import useHauls from "../../api/queries/useHauls";
import Loader from "../../components/primitives/Loader";
import HaulAssignmentHelper from "../../helpers/haulAssignmentHelper";
import useCreateTrip from "../../api/mutations/useCreateTrip";
import moment from "moment";
import AssignmentHelper from "../../helpers/assignmentHelper";
import DriverTripStatusMiddleware from "../../components/core/DriverTripStatusMiddleware";
import useUpdateTrip from "../../api/mutations/useUpdateTrip";
import { TripStatus } from "../../enums/status";
import { useState } from "react";
import JobHelper from "../../helpers/jobHelper";
import { queryClient } from "../../api/queryClient";
import { Queries } from "../../api/queries";

const DriverProjectDetails = () => {
  const navigate = useNavigate();
  const { assignment_id } = useParams();
  const [isRepeatClicked, setIsRepeatClicked] = useState(false);

  const { mutateAsync: startTrip, isPending } = useCreateTrip({
    onSuccess: () => {
      navigate(`/manage/driver/projects/${assignment_id}`);
    },
  });

  const { mutateAsync: updateTrip } = useUpdateTrip({
    onSuccess: () => {
      if (isRepeatClicked) {
        onClickStartProject();
      } else {
        queryClient.invalidateQueries({
          queryKey: [Queries.hauls],
        });
        navigate(`/driver/projects/${assignment_id}`);
      }
    },
  });

  const { data: hauls, isLoading: isHaulsLoading } = useHauls({
    assignment_id,
  });

  if (isHaulsLoading) {
    return <Loader />;
  }

  const haulsData = hauls[0];

  const haulHelper = new HaulAssignmentHelper(haulsData);

  const assignment = haulHelper.assignmentById(assignment_id);

  const assignmentHelper = new AssignmentHelper(assignment);

  const currentTrip = assignmentHelper.getTheCurrentTrip();

  const jobHelper = new JobHelper(haulHelper.job);

  const isAssignmentStarted = assignmentHelper.isAssignmentStarted();

  const { project_name, tons_completed, quantity } = haulHelper.job;

  const totalQuantity = parseInt(quantity);

  const onClickStartProject = () => {
    const payload = {
      job_assignment_id: assignment_id,
      load_quantity: 20,
      trip_date: moment().format("YYYY-MM-DD"),
    };
    startTrip(payload);
  };

  const repeat = () => {
    setIsRepeatClicked(true);
    if (currentTrip) {
      const payload = {
        trip_id: currentTrip?._id,
        status: TripStatus.COMPLETED,
      };
      updateTrip(payload);
    } else {
      onClickStartProject();
    }
  };

  const doneForTheDay = async () => {
    setIsRepeatClicked(false);
    if (currentTrip) {
      const payload = {
        trip_id: currentTrip?._id,
        status: TripStatus.COMPLETED,
      };
      updateTrip(payload);
    } else {
      navigate(-1);
    }
  };

  const status = currentTrip?.status;

  return (
    <DriverTripStatusMiddleware status={status}>
      <div className="space-y-8">
        <div className="flex justify-between">
          <h1 className="text-2xl flex-shrink-0 capitalize">
            Project - {project_name}
          </h1>
        </div>
        <div className="space-y-4">
          <ProjectProgressInfo
            percent={haulHelper.completedPercentage}
            total={totalQuantity}
            completed={tons_completed}
          />
          <div className="">
            <div className="grid md:grid-cols-2 gap-4 grid-cols-1 ">
              <DriverTripDropdown
                titleOne={assignmentHelper.driverName}
                tonsCompleted={assignmentHelper.totalLoadCompleted}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between mt-8 gap-8">
          {/* Map Here Hardcoded */}
          <div className="flex-1">
            <iframe
              title="map"
              width="100%"
              id="gmap_canvas"
              className="rounded aspect-square"
              src={[
                "https://maps.google.com/maps?q=",
                jobHelper.gps,
                "&t=&z=13&ie=UTF8&iwloc=&output=embed",
              ].join("")}
            ></iframe>
          </div>

          {isAssignmentStarted ? (
            <div className="flex-1 flex justify-center gap-8">
              <Button
                loading={isPending}
                onClick={() => {
                  repeat();
                }}
              >
                Repeat
              </Button>

              {currentTrip && (
                <Button
                  onClick={() => {
                    doneForTheDay();
                  }}
                >
                  Done For the Day
                </Button>
              )}
            </div>
          ) : (
            <div className="flex-1 flex justify-center">
              <Button onClick={onClickStartProject} loading={isPending}>
                Start Project
              </Button>
            </div>
          )}
        </div>
      </div>
    </DriverTripStatusMiddleware>
  );
};

export default DriverProjectDetails;
