import { useQuery } from "@tanstack/react-query";
import { Queries } from ".";
import { fetchHaulDetails } from "../services/trucker";

const useHauls = ({ job_id, enabled, assignment_id }) => {
  return useQuery({
    queryKey: [Queries.hauls],
    queryFn: () =>
      fetchHaulDetails({
        job_id,
        assignment_id,
      }),
    enabled,
    staleTime: 0,
    cacheTime: 0,
  });
};

export default useHauls;
