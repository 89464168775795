import { useQuery } from "@tanstack/react-query";

const { fetchQuarries } = require("../services/users");
const { Queries } = require(".");

const useQuarries = () => {
  return useQuery({
    queryKey: [Queries.quarries],
    queryFn: fetchQuarries,
  });
};

export default useQuarries;
