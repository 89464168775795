import { useMutation } from "@tanstack/react-query";
import { Mutations } from ".";
import { updateTripStatus } from "../services/drivers";

const useUpdateTrip = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: updateTripStatus,
    mutationKey: [Mutations.updateTripStatus],
    onSuccess,
  });
};

export default useUpdateTrip;
