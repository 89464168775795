// src/components/InvoiceTemplate.js
import React, { useEffect, useState } from "react";
import {
  Layout,
  Typography,
  Row,
  Col,
  Table,
  Form,
  InputNumber,
  Input,
  Button,
  Modal,
  Spin,
} from "antd";
import truckitezLogo from "./assets/img/logo_transparent.png";
import useSession from "./context/SessionContext";
import SubmitButton from "./components/Button";
import { API } from "./config/AppConstants";
import $ from "jquery";
import Toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";

const { Content } = Layout;
const { Text } = Typography;
let invoiceData = {};
const InvoiceTemplate = () => {
  let params = useParams();
  const navigate = useNavigate();
  let { user, business } = useSession();

  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [item, setItem] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  let totalAmount =
    user && user.role_id === "R001"
      ? item && item.totalAmount
      : item &&
        item.reduce((accumaltor, current) => {
          return (
            accumaltor +
            current.bid.bid_amount *
              (!current.actual_quantity
                ? current.hourly_minimum || current.quantity
                : current.actual_quantity)
          );
        }, 0);

  useEffect(() => {
    setLoading(true);
    if (user && user.role_id === "R001") {
      viewInvoiceDetails();
    } else {
      getInvoiceDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewInvoiceDetails = () => {
    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.INVOICE.GETINVOICE +
        "?invoice_id=" +
        params.invoice_id,
      type: "GET",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    }).then(async (res) => {
      let invoice_items = res.Item.invoice_items;
      invoice_items.totalAmount = res.Item.total_amount;
      setItem(invoice_items);
      let truckerPrfl = await fetchTruckerPrfl(res.Item.bid_company_id);
      invoiceInfo(
        truckerPrfl && truckerPrfl.profile,
        business && business.profile
      );
      setLoading(false);
    });
  };

  const invoiceInfo = (trucker, contractor) => {
    let truc_bill_address = trucker.billing_address;
    truc_bill_address =
      truc_bill_address && typeof truc_bill_address == "object"
        ? truc_bill_address
        : JSON.parse(truc_bill_address);
    let conc_bill_address = contractor.billing_address;
    conc_bill_address =
      conc_bill_address && typeof conc_bill_address == "object"
        ? conc_bill_address
        : JSON.parse(conc_bill_address);
    let dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + 1);
    invoiceData = {
      companyName: trucker.business_name,
      companyAddress:
        truc_bill_address &&
        truc_bill_address.address +
          "," +
          truc_bill_address.city +
          "," +
          truc_bill_address.state +
          "," +
          truc_bill_address.zipcode,
      invoiceNumber: "INV-0001",
      invoiceDate: new Date().toLocaleDateString(),
      dueDate: dueDate.toLocaleDateString(),
      billTo: {
        name: contractor.business_name,
        address:
          conc_bill_address &&
          conc_bill_address.address +
            "," +
            conc_bill_address.city +
            "," +
            conc_bill_address.state +
            "," +
            conc_bill_address.zipcode,
      },
    };
  };

  const fetchTruckerPrfl = (company_id) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url:
          API.API_URL +
          API.API_VER +
          API.API_ENDPOINTS.LOGIN.BUSINESS_PRFL +
          "?company_id=" +
          company_id,
        type: "GET",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        dataType: "json",
      })
        .then(async (res) => {
          resolve(res);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          resolve("failed to fetch the business profile!", jqXHR);
        });
    });
  };

  const getInvoiceDetails = () => {
    if (!user) return;

    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.INVOICE.INVOICEINFO +
        "?bid_id=" +
        params.bid_id +
        "&job_id=" +
        params.job_id,
      type: "GET",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    })
      .then(async (res) => {
        let job = { ...res.jobInfo };
        job.bid = res.bidInfo;
        job = [job];
        setItem(job);
        invoiceInfo(res.bidBusinessProfile, res.jobBusinessProfile);
        setLoading(false);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Product",
      dataIndex: user && user.role_id === "R001" ? "product" : "material",
      render: (material, record, i) => {
        return (
          <span className={user.role_id === "R001" ? "" : "anchor_link"}>
            {material}
          </span>
        );
      },
      onCell: (record, recordIndex) => ({
        onClick: () => {
          user.role_id !== "R001" && onCellSelected(record);
        },
      }),
    },
    {
      title: "Est. Qty",
      dataIndex: "quantity",
      render: (quantity, record, i) => {
        return <span>{record.hourly_minimum || quantity}</span>;
      },
    },
    {
      title: "Actual Quantity",
      dataIndex: "quantity",
      render: (quantity, record, i) => {
        return (
          <span>
            {!record.actual_quantity
              ? record.hourly_minimum || quantity
              : record.actual_quantity}
          </span>
        );
      },
    },
    {
      title: "Unit Price",
      dataIndex: user && user.role_id === "R001" ? "unit_price" : "bid",
      render: (bid, record, i) => {
        return <span>{user.role_id === "R001" ? bid : bid.bid_amount}</span>;
      },
    },
    {
      title: "Total Price",
      dataIndex: user && user.role_id === "R001" ? "total" : "bid",
      render: (bid, record, i) => {
        return (
          <span>
            {user.role_id === "R001"
              ? bid
              : bid.bid_amount *
                (!record.actual_quantity
                  ? record.hourly_minimum || record.quantity
                  : record.actual_quantity)}
          </span>
        );
      },
    },
  ];

  const onFinish = (values) => {
    if (selectedRow) {
      let itemVal = [...item];
      let selectedItemIndex = itemVal.findIndex(
        (m) => m.material === selectedRow.material
      );
      if (itemVal[selectedItemIndex] && !itemVal[selectedItemIndex].job_id) {
        itemVal[selectedItemIndex].material = values.material;
        itemVal[selectedItemIndex].bid.bid_amount = values.price;
      }
      itemVal[selectedItemIndex].actual_quantity = values.actual_quantity;
      setItem(itemVal);
      setModalVisible(false);
      setSelectedRow(null);
    } else {
      let itemVal = JSON.parse(JSON.stringify(item));
      let bid = JSON.parse(JSON.stringify(itemVal[0].bid));
      bid.bid_amount = values.price;
      itemVal.push({ ...values, bid });
      setItem(itemVal);
      setModalVisible(false);
    }
  };

  const onCellSelected = (record) => {
    setSelectedRow(record);
    form.setFieldsValue({
      price: record.bid.bid_amount,
      quantity: record.quantity || record.hourly_minimum,
      material: record.material,
      actual_quantity: !record.actual_quantity
        ? record.hourly_minimum || record.quantity
        : record.actual_quantity,
    });
    setModalVisible(true);
  };

  const addItem = () => {
    form.setFieldsValue({ material: "", quantity: "", price: "" });
    setModalVisible(true);
  };

  const handleSubmit = () => {
    let itemData = [];
    for (const itemInfo of item) {
      itemData.push({
        product: itemInfo.material,
        quantity: itemInfo.hourly_minimum || itemInfo.quantity,
        actual_quantity: !itemInfo.actual_quantity
          ? itemInfo.hourly_minimum || itemInfo.quantity
          : itemInfo.actual_quantity,
        unit_price: itemInfo.bid.bid_amount,
        total:
          itemInfo.bid.bid_amount *
          (!itemInfo.actual_quantity
            ? itemInfo.hourly_minimum || itemInfo.quantity
            : itemInfo.actual_quantity),
      });
    }
    let data = {
      form_input: {
        job_id: params.job_id,
        bid_id: params.bid_id,
        bid_company_id: item[0].bid.company_id,
        job_company_id: item[0].company_id,
        items: itemData,
        total_amount: totalAmount,
        action: "submitInvoice",
      },
    };
    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.INVOICE.INVOICEINFO,
      type: "POST",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          navigate("/projects/completed");
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
      }
    );
  };

  return (
    <React.Fragment>
      {Object.keys(invoiceData).length > 0 && !loading ? (
        <Layout>
          <Content style={{ padding: "50px" }}>
            <Row>
              <Col span={12}>
                <Typography.Link alt={"TruckingEZ"}>
                  <img
                    style={{ marginBottom: "45px", borderRadius: "5px" }}
                    alt="TruckingEZ"
                    width="100px"
                    height="18px"
                    src={truckitezLogo}
                  ></img>
                </Typography.Link>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Text strong>{invoiceData.companyName}</Text>
                <br />
                {/* <Text strong>{invoiceData.projectName}</Text><br /> */}
                <Text>{invoiceData.companyAddress}</Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text strong>Invoice #: </Text>
                {invoiceData.invoiceNumber}
                <br />
                <Text strong>Invoice Date: </Text>
                {invoiceData.invoiceDate}
                <br />
                <Text strong>Due Date: </Text>
                {invoiceData.dueDate}
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col span={12}>
                <Text strong>Bill To:</Text>
                <br />
                <Text>{invoiceData.billTo.name}</Text>
                <br />
                <Text>{invoiceData.billTo.address}</Text>
              </Col>
            </Row>
            {user && user.role_id !== "R001" && (
              <Row>
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    type="primary"
                    style={{ marginTop: "5px" }}
                    onClick={() => addItem()}
                  >
                    Add Item
                  </Button>
                </Col>
              </Row>
            )}
            <div
              className="ant-table-custom-wrapper"
              style={{ marginTop: "15px" }}
            >
              <Table
                columns={columns}
                dataSource={item}
                pagination={false}
                summary={() => (
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      colSpan={4}
                      style={{ textAlign: "right" }}
                    >
                      <Text strong>Total</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text strong>{totalAmount}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              />
            </div>
            {user.role_id !== "R001" && (
              <Col
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  marginTop: "15px",
                }}
              >
                <Button type="primary" onClick={() => handleSubmit()}>
                  Submit
                </Button>
              </Col>
            )}
          </Content>
        </Layout>
      ) : (
        <div className="center-container">
          <Spin tip="Loading"></Spin>
        </div>
      )}
      <Modal
        title={selectedRow ? "Edit Item" : "Add Item"}
        open={modalVisible}
        width={600}
        onCancel={() => {
          setModalVisible(false);
          setSelectedRow(null);
        }}
        style={{ top: 60 }}
        destroyOnClose={true}
        footer={false}
      >
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            maxWidth: "100%",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="nest-messages"
            labelCol={{ span: 18 }}
            wrapperCol={{ span: 22 }}
            style={{ maxWidth: 700, margin: "15px 30px" }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item label={"Description"} name={"material"}>
                  <Input disabled={selectedRow && selectedRow.job_id} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={selectedRow ? "Actual Quantity" : "Quantity"}
                  name={selectedRow ? "actual_quantity" : "quantity"}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item label={"Unit Price"} name={"price"}>
                  <InputNumber
                    disabled={selectedRow && selectedRow.job_id}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Col
              style={{
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "15px",
                marginRight: "18px",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setModalVisible(false);
                  setSelectedRow(null);
                }}
              >
                Cancel
              </Button>
              <SubmitButton form={form}>{"Submit"}</SubmitButton>
            </Col>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default InvoiceTemplate;
