import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Row,
  Steps,
} from "antd";
import useSession from "../context/SessionContext";
import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import SubmitButton from "../components/Button";
import $ from "jquery";
import { API } from "../config/AppConstants";
import Toastr from "toastr";

const { Step } = Steps;

const BusinessProfile = () => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const { user, business, setBusiness } = useSession();
  const [modalVisible, setModalVisible] = useState(false);
  const [businessProfile, setBusinessProfile] = useState(
    business && business.profile
  );

  const fetchBusinessProfile = () => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url:
          API.API_URL +
          API.API_VER +
          API.API_ENDPOINTS.LOGIN.BUSINESS_PRFL +
          "?company_id=" +
          user.company_id,
        type: "GET",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        dataType: "json",
      })
        .then(async (res) => {
          setBusinessProfile(res && res.profile);
          setModalVisible(false);
          setBusiness(res);
          resolve(res);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.status === 0) {
            if (jqXHR.status === 0) {
              Toastr.error("Failed to fetch the business profile!");
            }
          }
          resolve("failed to fetch the business profile!");
        });
    });
  };

  const formatAddress = (a) => {
    let addressParts = [];
    try {
      let address = typeof a == "object" ? a : JSON.parse(a);
      for (let i of ["address", "city", "state", "zipcode"]) {
        if (address[i]) {
          addressParts.push(address[i]);
          addressParts.push(<br />);
        }
      }
    } catch (error) {
      console.log("Address Error: ", a);
    }
    return <div>{addressParts}</div>;
  };

  let allowedFields = [
    { field: "business_name", label: "Name" },
    { field: "fed_tax_id", label: "Fed. Tax Id" },
    {
      field: "billing_address",
      label: "Billing Address",
      formatter: formatAddress,
    },
    {
      field: "physical_address",
      label: "Physical Address",
      formatter: formatAddress,
    },
  ];

  let items = [];
  for (let k of allowedFields) {
    let data = businessProfile && businessProfile[k.field];
    if (k.formatter) data = k.formatter(data);
    items.push({ label: k.label, children: data });
  }

  const next = () => {
    form
      .validateFields()
      .then(() => {
        setCurrent(current + 1);
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = () => {
    console.log("Success:", form.getFieldsValue(true));
    const value = form.getFieldsValue(true);
    let data = {
      form_input: {
        billing_address: JSON.stringify({
          address: value["address_1"],
          city: value["address_1_city"],
          state: value["address_1_state"],
          zipcode: value["address_1_zipcode"],
        }),
        physical_address: JSON.stringify({
          address: value["address_2"],
          city: value["address_2_city"],
          state: value["address_2_state"],
          zipcode: value["address_2_zipcode"],
        }),
        business_name: value.business_name.trim(),
        fed_tax_id: value.fed_tax_id,
      },
    };

    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.BUSINESS_PRFL.BUSINESS_PRFL,
      type: "PUT",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      async (res) => {
        if (res) {
          Toastr.success("Business Profile Update successfully!");
          await fetchBusinessProfile();
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
      }
    );
  };

  const zipCodeValidator = (_, value) => {
    let zipPattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (value && !zipPattern.test(value)) {
      return Promise.reject("Enter Valid Zip Code");
    }
    return Promise.resolve();
  };

  const steps = [
    {
      title: "Basic Info",
      content: (
        <div>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="business_name"
                label="Business name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Business name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="fed_tax_id" label="Federal Tax ID">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_1"
                label="Billing Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Billing Address",
                  },
                ]}
              >
                <TextArea rows={2} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_1_city"
                label="City"
                rules={[
                  { required: true, message: "Please enter your last name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_1_state"
                label="State"
                rules={[{ required: true, message: "Please enter your State" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_1_zipcode"
                label="Zip Code"
                rules={[
                  { required: true, message: "Please enter your Zip Code" },
                  { validator: zipCodeValidator },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      title: "Location",
      content: (
        <div>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_2"
                label="Physical Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Physical Address",
                  },
                ]}
              >
                <TextArea rows={2} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_2_city"
                label="City"
                rules={[{ required: true, message: "Please enter your City" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_2_state"
                label="State"
                rules={[{ required: true, message: "Please enter your State" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="address_2_zipcode"
                label="Zip Code"
                rules={[
                  { required: true, message: "Please enter your Zip Code" },
                  { validator: zipCodeValidator },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  let billing_address = {};
  let physical_address = {};

  if (businessProfile) {
    if (businessProfile.address) {
      billing_address = businessProfile.address;
    } else {
      if (businessProfile.billing_address) {
        if (typeof businessProfile.billing_address == "object") {
          billing_address = businessProfile.billing_address;
          physical_address = businessProfile.physical_address;
        } else {
          billing_address = JSON.parse(businessProfile.billing_address);
          physical_address = JSON.parse(businessProfile.physical_address);
        }
      }
    }
  }

  return (
    <div className="ant-table-custom-wrapper" xs={24} md={12}>
      {businessProfile && (
        <Card
          title="Business Profile"
          extra={
            <EditOutlined
              key="edit"
              style={{ cursor: "pointer" }}
              onClick={() => setModalVisible(true)}
            />
          }
        >
          <Descriptions items={items} />
        </Card>
      )}
      <Modal
        title={"Edit Business Profile"}
        maskClosable={false}
        open={modalVisible}
        width={600}
        onCancel={() => setModalVisible(false)}
        style={{ top: 60 }}
        destroyOnClose={true}
        footer={false}
      >
        {billing_address && physical_address && businessProfile && (
          <div>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              style={{ marginTop: "25px" }}
              initialValues={{
                business_name: businessProfile.business_name,
                fed_tax_id: businessProfile.fed_tax_id,
                address_1: billing_address.address,
                address_1_city: billing_address.city,
                address_1_state: billing_address.state,
                address_1_zipcode: billing_address.zipcode,
                address_2: physical_address.address,
                address_2_city: physical_address.city,
                address_2_state: physical_address.state,
                address_2_zipcode: physical_address.zipcode,
              }}
            >
              <div>{steps[current].content}</div>
              <div className="steps-action align-right">
                {current < steps.length - 1 && (
                  <Button type="primary" onClick={() => next()}>
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <SubmitButton form={form}>Update</SubmitButton>
                )}
                {current > 0 && (
                  <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                    Previous
                  </Button>
                )}
              </div>
            </Form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BusinessProfile;
