import * as Yup from "yup";

export const initialValues = {
  arrivedAtQuary: false,
  photoAtQuary: null,
  arrivedAtDestination: false,
  photoAtDestination: null,
};

export const quaryArrivalSchema = Yup.object().shape({
  //this should be true;
  arrivedAtQuary: Yup.bool().oneOf(
    [true],
    "You have not arrived at the location."
  ),
});

export const quaryPhotoSchema = Yup.object().shape({
  photoAtQuary: Yup.string().required("Photo is required"),
});

export const destinationArrivalSchema = Yup.object().shape({
  arrivedAtDestination: Yup.bool().oneOf(
    [true],
    "You have not arrived at the location."
  ),
});

export const destinationPhotoSchema = Yup.object().shape({
  photoAtDestination: Yup.string().required("Photo is required"),
});
