import { LoadingOutlined } from "@ant-design/icons";

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-24 ">
      <LoadingOutlined />
    </div>
  );
};

export default Loader;
