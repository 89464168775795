import { createContext, useContext, useEffect, useState } from "react";
import SessionConfig from "./SessionConfig";

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
    const [user, setUser] = useState(SessionConfig.get('user'));
    const [business, setBusiness] = useState(SessionConfig.get('business'));

    useEffect(() => {
        SessionConfig.set('user', user);
        SessionConfig.set('business', business);
    }, [user, business]);

    return (
        <SessionContext.Provider value={{ user, setUser, business, setBusiness }}>
            <div id={new Date()}>
                {children}
            </div>
        </SessionContext.Provider>
    );
};

const useSession = () => {
    let user = SessionConfig.get('user');
    const context = useContext(SessionContext);
    if (!user)
        context.user = user;
    if (context === undefined) {
        throw new Error("useSession must be used within a SessionProvider");
    }
    return context;
};

export default useSession;