import { useState, useEffect } from "react";
import toastr from "toastr";

const useGeoLocation = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!navigator.geolocation) {
      setError({
        code: 0,
        message: "Geolocation is not supported by this Browser.",
      });
      toastr.error("Geolocation is not supported by this Browser.");
      setLoading(false);
      return;
    }

    const handleSuccess = (position) => {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      setError(null);
      setLoading(false);
    };

    const handleError = (error) => {
      setError({
        code: error.code,
        message: error.message,
      });
      toastr.error(error.message);
      setLoading(false);
    };

    const requestLocation = () => {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    };

    requestLocation();
  }, []);

  return { location, error, loading };
};

export default useGeoLocation;
