import { useQuery } from "@tanstack/react-query";
import { Queries } from ".";
import { fetchTrucks } from "../services/trucks";

const useTrucks = () => {
  return useQuery({
    queryKey: [Queries.users],
    queryFn: fetchTrucks,
  });
};

export default useTrucks;
