import { Form, Formik } from "formik";
import {
  destinationArrivalSchema,
  destinationPhotoSchema,
  initialValues,
  quaryArrivalSchema,
  quaryPhotoSchema,
} from "./ManageDriverProject.utils";
import ArrivedAtQuarryFormPhase from "./TripPhases/ArrivedAtSource.view";
import QuarryPhotoPickerFormPhase from "./TripPhases/QuaryPhotoPicker.view";
import ArrivedAtDestinationFormPhase from "./TripPhases/ArrivedAtDestination.view";
import DestinationPhotoPickerFormPhase from "./TripPhases/DestinationPhotoPicker.view";
import $diverProjectManagement from "../../../store/manage-driver-project";
import { useNavigate, useParams } from "react-router-dom";
import { queryClient } from "../../../api/queryClient";
import { Queries } from "../../../api/queries";
import useHauls from "../../../api/queries/useHauls";
import HaulAssignmentHelper from "../../../helpers/haulAssignmentHelper";
import AssignmentHelper from "../../../helpers/assignmentHelper";
import Loader from "../../primitives/Loader";

const ManageDriverProject = () => {
  const { assignment_id } = useParams();

  const currentFormPhase = $diverProjectManagement.selectors.useCurrentPhase();
  const navigate = useNavigate();
  const validations = [
    quaryArrivalSchema,
    quaryPhotoSchema,
    destinationArrivalSchema,
    destinationPhotoSchema,
  ];

  const { data: hauls, isLoading: isHaulsLoading } = useHauls({
    assignment_id,
  });

  if (isHaulsLoading) {
    return <Loader />;
  }

  const formPhases = [
    {
      title: "Quary Arrival",
      render: () => <ArrivedAtQuarryFormPhase />,
    },
    {
      title: "Quary Arrival Photo",
      render: () => <QuarryPhotoPickerFormPhase />,
    },
    {
      title: "Destination Arrival",
      render: () => <ArrivedAtDestinationFormPhase />,
    },
    {
      title: "Destination Arrival Photo",
      render: () => <DestinationPhotoPickerFormPhase />,
    },
  ];

  const haulsData = hauls[0];

  const haulHelper = new HaulAssignmentHelper(haulsData);

  const assignment = haulHelper.assignmentById(assignment_id);

  const assignmentHelper = new AssignmentHelper(assignment);

  const currentTrip = assignmentHelper.getTheCurrentTrip();

  const status = currentTrip?.status;

  return (
    // <DriverTripStatusMiddleware status={status}>
    <Formik
      initialValues={initialValues}
      validationSchema={validations[currentFormPhase]}
      onSubmit={(values) => {
        $diverProjectManagement.actions.addTrips(values);
        $diverProjectManagement.actions.reset();
        navigate(`/driver/projects/${assignment_id}`, {
          replace: true,
        });
        queryClient.invalidateQueries({
          queryKey: [Queries.hauls],
        });
      }}
    >
      {() => {
        return (
          <Form>
            <div className="flex justify-center">
              {formPhases[currentFormPhase].render()}
            </div>
          </Form>
        );
      }}
    </Formik>
    // </DriverTripStatusMiddleware>
  );
};

export default ManageDriverProject;
