const { useEffect } = require("react");
const { TripStatus } = require("../../enums/status");
const { useNavigate, useParams } = require("react-router-dom");
const {
  default: $diverProjectManagement,
} = require("../../store/manage-driver-project");

const DriverTripStatusMiddleware = ({ status, children }) => {
  const { assignment_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    switch (status) {
      case TripStatus.STARTED:
        $diverProjectManagement.actions.setPhase(0);
        navigate(`/manage/driver/projects/${assignment_id}`, { replace: true });
        break;
      case TripStatus.ARRIVED_AT_QUARRY:
        $diverProjectManagement.actions.setPhase(2);
        navigate(`/manage/driver/projects/${assignment_id}`, { replace: true });
        break;
      default:
        $diverProjectManagement.actions.reset();
        navigate(`/driver/projects/${assignment_id}`, { replace: true });
        break;
    }
  }, [status]);

  return <div className="">{children}</div>;
};

export default DriverTripStatusMiddleware;
