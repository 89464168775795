import "./App.css";
import { ConfigProvider, Layout } from "antd";
import { SessionProvider } from "./context/SessionContext";
import Router from "./context/Router";
import MobileRouter from "./context/MobileRouter";
import { THEME_DARK } from "./config/AppConstants";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./api/queryClient";
import ErrorBoundary from "./components/primitives/ErrorBoundry";

function App() {
  let isMobile = window.innerWidth <= 600;
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <SessionProvider>
          <ConfigProvider theme={THEME_DARK}>
            <Layout hasSider={false}>
              {isMobile ? <MobileRouter /> : <Router />}
            </Layout>
          </ConfigProvider>
        </SessionProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
