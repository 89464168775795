import { useFormikContext } from "formik";
import { entryStatus } from "./ManageProject.utils";

const ConfirmSelectionFormPhase = () => {
  const form = useFormikContext();
  const trucks = form.values.haulers.filter(
    (m) => m.status === entryStatus.ADDED || m.status === entryStatus.FINALIZED
  );
  return (
    <div className="w-full min-w-[300px] max-h-[300px] overflow-scroll min-h-[300px] bg-dark-bg rounded-md">
      <div className="flex justify-between bg-[#782627] py-2">
        <div className="flex-1 flex justify-center items-center font-bold">
          Truck
        </div>
        <div className="flex-1 flex justify-center items-center font-bold">
          Driver
        </div>
      </div>
      {trucks.map((truck, index) => {
        return (
          <div className="flex justify-between py-2">
            <div className="flex-1 flex justify-center items-center">
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-[50%] bg-pastel-red-100" />
                <h1 className=" select-none truncate ... max-w-20">
                  {truck.truck_type}
                </h1>
              </div>
            </div>
            <div className="flex-1 flex justify-center items-center">
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-[50%] bg-pastel-red-100" />
                <h1 className=" select-none">
                  {truck.driver.first_name} {truck.driver.last_name}
                </h1>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ConfirmSelectionFormPhase;
