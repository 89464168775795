import { useQuery } from "@tanstack/react-query";
import { Queries } from ".";
import { fetchDriverJobs } from "../services/drivers";

const useDriverProjects = (date) => {
  return useQuery({
    queryKey: [Queries.driverProjects],
    queryFn: () =>
      fetchDriverJobs({
        date,
      }),
  });
};

export default useDriverProjects;
