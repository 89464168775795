import { API } from "../../config/AppConstants";
import { apiHelper } from "../instance";

export const fetchMines = async ({ useGet = 0, company_id }) => {
  const { data } = await apiHelper.get(API.API_ENDPOINTS.MINE.MINE, {
    params: {
      company_id,
      useGet,
    },
  });
  return data;
};
