import React, { useEffect, useState } from "react";
import $ from "jquery";
import { API } from "../config/AppConstants";
import useSession from "../context/SessionContext";
import { Button, Modal, Table, Upload } from "antd";
import { FaDownload } from "react-icons/fa6";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import Toastr from "toastr";
import { AiOutlineDelete } from "react-icons/ai";

const Docs = () => {
  const { user, business } = useSession();
  const [files, setFiles] = useState([]);
  const [docType, setDocType] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  function getUUID() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : r & (0x3 | 0x8)).toString(16);
      }
    );
    return uuid;
  }

  const docColumns = [
    {
      title: "Document Name",
      dataIndex: "Metadata.doc_type",
      key: "Metadata.doc_type",
      render: (Metadata, record, i) => {
        return <span className="anchor_link">{"Other Documents"}</span>;
      },
      onCell: (record, recordIndex) => ({
        onClick: () => onCellSelected(record),
      }),
    },
    {
      title: "File Name",
      dataIndex: "Metadata.file_name",
      key: "Metadata.file_name",
      render: (user_name, record, i) => {
        return <span>{record.Metadata.file_name}</span>;
      },
    },
    {
      title: "Uploaded By",
      dataIndex: "Metadata.user_login",
      key: "Metadata.owner",
      render: (user_name, record, i) => {
        return <span>{record.Metadata.owner}</span>;
      },
    },
    {
      title: "TimeStamp",
      dataIndex: "LastModified",
      key: "LastModified",
      render: (user_name, record, i) => {
        let localDT = new Date(record.Metadata.timestamp);
        return <span>{localDT.toLocaleString()}</span>;
      },
    },
    {
      title: "Settings",
      dataIndex: "LastModified",
      key: "LastModified",
      render: (user_name, record, i) => {
        return (
          <div>
            <a href={record.SecureUrl} target="_blank" rel="noreferrer">
              <FaDownload />
            </a>
            {record &&
              record.doc_type &&
              record.doc_type.includes("DOC_OTHER") && (
                <span
                  style={{
                    marginLeft: "6px",
                    color: "rgb(220, 68, 70)",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDelete(record)}
                >
                  <AiOutlineDelete size={16} />
                </span>
              )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (user) {
      fetchDocuments();
      fetchDocType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDocType = () => {
    setLoading(true);
    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.COMMON_CONFIG.COMMON_CONFIG +
        "?config_id=DOC_TYPE",
      type: "GET",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    })
      .then(async (res) => {
        setDocType(res && res.Item && res.Item.config);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        setLoading(false);
      });
  };

  const onCellSelected = (record) => {
    setSelectedRow(record);
    setModalVisible(true);
  };

  const fetchDocuments = () => {
    setLoading(true);
    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.S3.DOCS +
        "?company_id=" +
        user.company_id +
        "&useGet=0",
      type: "GET",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    })
      .then(async (res) => {
        setLoading(false);
        setFiles(res);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        setLoading(false);
      });
  };

  const uploadDocument = ({ file, onSuccess, onError }) => {
    setUploading(true);
    return new Promise((resolve, reject) => {
      let data = {
        form_input: {
          file_name: file.name,
          mime_type: file.type,
          file_extn: file.name.split(".").pop(),
          company_id: user.company_id,
          doc_type: file.doc_type,
        },
      };
      $.ajax({
        url: API.API_URL + API.API_VER + API.API_ENDPOINTS.LOGIN.FILE_UPLOAD,
        type: "POST",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        data: JSON.stringify(data),
      }).then(
        async (res) => {
          resolve(res);
          let blobData = new Blob([file], { type: file.type });
          const result = await fetch(res.url, {
            method: "PUT",
            body: blobData,
          });

          let docs_info = {
            file_name: res.file_name,
            original_file_name: file.name,
            company_id: business.profile.company_id,
            doc_type: file.doc_type,
          };
          await updateDocs(docs_info);
          if (result.status === 200) {
            onSuccess(res.data);
            setUploading(false);
          } else {
            setUploading(false);
            Toastr.success("Failed to upload the file!");
          }
        },
        (err) => {
          resolve("Something went wrrong");
          onError(err);
          setUploading(false);
        }
      );
    });
  };

  const updateDocs = (fileData) => {
    return new Promise((resolve, reject) => {
      let data = { form_input: { ...fileData } };
      $.ajax({
        url: API.API_URL + API.API_VER + API.API_ENDPOINTS.DOCS.DOC_STORE,
        type: "PUT",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        data: JSON.stringify(data),
      }).then(
        (res) => {
          resolve(res);
          setModalVisible(false);
          fetchDocuments();
        },
        (err) => {
          resolve("Something went wrrong");
        }
      );
    });
  };
  const handleDelete = (rec) => {
    setLoading(true);
    debugger;
    return new Promise((resolve, reject) => {
      let data = {
        form_input: {
          company_id: rec.company_id,
          doc_type: rec.doc_type,
        },
      };
      $.ajax({
        url: API.API_URL + API.API_VER + API.API_ENDPOINTS.DOCS.DOC_STORE,
        type: "DELETE",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        data: JSON.stringify(data),
      }).then(
        (res) => {
          resolve(res);
          fetchDocuments();
          setLoading(false);
        },
        (xhr) => {
          if (xhr.status === 200) {
            Toastr.success(
              `${
                docType[rec.doc_type] || "Other Documents"
              } deleted Successfully!`
            );
            fetchDocuments();
            setLoading(false);
          }
          setLoading(false);
          resolve("Something went wrrong");
        }
      );
    });
  };

  return (
    <React.Fragment>
      <div style={{ textAlign: "end" }}>
        <Upload
          customRequest={uploadDocument}
          beforeUpload={(file) => {
            const isJpgOrPng =
              file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "application/pdf";
            if (!isJpgOrPng) {
              Toastr.error("Supported file types are : PDF, JPEG, PNG");
              return Upload.LIST_IGNORE;
            }
            let doc = file;
            let uniqueId = getUUID();
            doc.doc_type = `DOC_OTHER_${uniqueId}`;
            return true;
          }}
          onChange={(info) => {
            if (info.file.status === "done") {
              Toastr.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === "error") {
              Toastr.error(`${info.file.name} file upload failed.`);
            }
          }}
          showUploadList={false}
          maxCount={1}
        >
          <Button
            icon={
              uploading && !selectedRow ? (
                <LoadingOutlined />
              ) : (
                <UploadOutlined />
              )
            }
            style={{
              textAlign: "center",
              width: "200px",
              borderRadius: "13px",
              marginBottom: "15px",
            }}
          >
            Add Other Documents
          </Button>
        </Upload>
      </div>
      <div className="ant-table-custom-wrapper">
        <Table
          rowKey={(record) => record.Metadata.file_name}
          columns={docColumns}
          dataSource={files}
          loading={loading}
          pagination={false}
        />
        <Modal
          title={
            selectedRow &&
            typeof docType[selectedRow.Metadata.doc_type] === "string"
              ? docType[selectedRow.Metadata.doc_type]
              : "Other Documents"
          }
          maskClosable={false}
          open={modalVisible}
          width={600}
          onCancel={() => {
            setModalVisible(false);
          }}
          style={{ top: 60 }}
          destroyOnClose={true}
          footer={false}
        >
          <Upload
            customRequest={uploadDocument}
            beforeUpload={(file) => {
              const isJpgOrPng =
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "application/pdf";
              if (!isJpgOrPng) {
                Toastr.error("Supported file types are : PDF, JPEG, PNG");
                return Upload.LIST_IGNORE;
              }
              let doc = file;
              doc.doc_type = selectedRow.Metadata.doc_type;
              return true;
            }}
            onChange={(info) => {
              if (info.file.status === "done") {
                Toastr.success(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === "error") {
                Toastr.error(`${info.file.name} file upload failed.`);
              }
            }}
            maxCount={1}
            showUploadList={false}
          >
            <Button
              icon={
                uploading && selectedRow ? (
                  <LoadingOutlined />
                ) : (
                  <UploadOutlined />
                )
              }
              style={{
                textAlign: "center",
                width: "300px",
                borderRadius: "13px",
                marginTop: "25px",
              }}
            >
              Update{" "}
              {selectedRow &&
              typeof docType[selectedRow.Metadata.doc_type] === "string"
                ? docType[selectedRow.Metadata.doc_type]
                : "Other Documents"}
            </Button>
          </Upload>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Docs;
