export const StatusEnum = {
  DISPATCH: "Dispatch",
  PENDING: "Pending",
  DECLINED: "Declined",
  UNASSIGNED: "Unassigned",
  CONTRACTED: "Contracted",
};

export const TripStatus = {
  STARTED: "started",
  ARRIVED_AT_QUARRY: "arrived_at_quarry",
  ARRIVED_AT_DESTINATION: "arrived_at_destination",
  COMPLETED: "completed",
};
