import { useFormikContext } from "formik";
import { CheckCircleOutlined } from "@ant-design/icons";
import $projectManagement from "../../../store/manage-project";
import { entryStatus } from "./ManageProject.utils";
import FormikErrorMessage from "../../primitives/FormikErrorMessage";

const SelectTruckDriverFormPhase = () => {
  const form = useFormikContext();
  const drivers = form.values.drivers;

  const lastSelectedDriverId =
    $projectManagement.selectors.useLastSelectedDriverId();

  const lastSelectedTruckId =
    $projectManagement.selectors.useLastSelectedTruckId();

  const selectedTruckId = $projectManagement.selectors.useSelectedTruckId();

  return (
    <div className="space-y-4">
      <h1 className="text-center">Drivers available from 7/25/24</h1>
      <div className="w-full min-w-[300px] max-h-[300px] overflow-scroll min-h-[300px] bg-dark-bg rounded-md">
        {drivers.map((driver, index) => {
          const status = driver.status || entryStatus.UNASSIGNED;
          const isSelected = status === entryStatus.ADDED;
          const isFinalised = status === entryStatus.FINALIZED;
          const isEvenTile = (index & 1) === 0;
          const firstTileRadius = index === 0 ? "rounded-t-md" : "";
          const lastTileRadius =
            index === drivers.length - 1 ? "rounded-b-md" : "";
          return (
            <div
              onClick={() => {
                const driverObjectKey = `drivers.${index}`;
                const selectedTruckId = lastSelectedTruckId;
                if (isFinalised) return;
                if (lastSelectedDriverId) {
                  form.setFieldValue(selectedTruckId + ".driver", null);
                  form.setFieldValue(
                    lastSelectedDriverId + ".status",
                    entryStatus.UNASSIGNED
                  );
                }
                $projectManagement.actions.setLastSelectedDriverId(
                  driverObjectKey
                );
                form.setFieldValue(
                  driverObjectKey + ".status",
                  entryStatus.ADDED
                );
                form.setFieldValue(selectedTruckId + ".driver", {
                  ...driver,
                  status: entryStatus.ADDED,
                });
                $projectManagement.actions.setSelectedDriverId(driverObjectKey);
              }}
              className={`flex items-center justify-between cursor-pointer  px-4 py-3 
              ${isEvenTile ? " bg-[#242424]" : "bg-[#161616]"} 
              ${firstTileRadius} ${lastTileRadius} 
              ${isSelected ? "border-2 border-primary" : ""}
          `}
            >
              <div className="flex items-center gap-4">
                <div className="w-8 h-8 rounded-[50%] bg-pastel-red-100" />
                <h1 className=" select-none font-bold">
                  {driver.first_name} {driver.last_name}
                </h1>
              </div>
              <div className="flex items-center gap-4">
                {isFinalised && <CheckCircleOutlined />}
              </div>
            </div>
          );
        })}
      </div>
      <FormikErrorMessage name="drivers" />
    </div>
  );
};

export default SelectTruckDriverFormPhase;
