import { API } from "../../config/AppConstants";
import $sessionStore from "../../store/session";
import { apiHelper } from "../instance";

export const fetchJobs = async ({
  job_status,
  assignedTo,
  useGet = 0,
  job_id,
}) => {
  const { company_id } = $sessionStore.actions.getUser();
  const { data } = await apiHelper.get(API.API_ENDPOINTS.CONTRACTOR.JOBS, {
    params: {
      company_id: company_id,
      useGet,
      job_status,
      assignedTo,
      job_id,
    },
  });
  return data;
};
