import { create } from "zustand";

// import { devtools } from "zustand/middleware";
import { persist } from "zustand/middleware";

const STORAGE_KEY = "truckitez-session";

const useSessionStore = create(
  persist(
    () => ({
      user: null,
    }),
    {
      name: STORAGE_KEY,
      getStorage: () => sessionStorage,
    }
  )
);

const selectors = {
  useCurrentPhase: () => useSessionStore((state) => state.currentPhase),
  useUser: () => useSessionStore((state) => state.user),
};

const actions = {
  set: useSessionStore.setState,
  setUser: (user) => {
    const set = useSessionStore.setState;
    set((state) => {
      state.user = user;
      return { ...state };
    });
  },
  getUserToken: () => useSessionStore.getState().user?.user_token,
  getUserLogin: () => useSessionStore.getState().user?.user_login,
  getHeaders: () => {
    const user = useSessionStore.getState().user;
    return {
      user_login: user.user_login,
      user_token: user.user_token,
    };
  },
  getUser: () => useSessionStore.getState().user,
};

/**
 * **Session Store**
 *
 * this store is used to store session
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $sessionStore = {
  actions,
  selectors,
};

export default $sessionStore;
