import { Steps, Button, Modal } from "antd";

import CardWrap from "../../components/CardWrapper";
import { useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import React from "react";
import ManageProject from "../../components/modules/ManageProject/ManageProject.view";

import moment from "moment";
import useJob from "../../api/queries/useJob";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/primitives/Loader";
import { queryClient } from "../../api/queryClient";
import { Queries } from "../../api/queries";
import useHauls from "../../api/queries/useHauls";
import HaulAssignmentHelper from "../../helpers/haulAssignmentHelper";
import AssignmentHelper from "../../helpers/assignmentHelper";
import DriverTripDropdown from "../../components/primitives/DriverTripDropdown";
import TripInfo from "../../components/primitives/TripInfo";
import ProjectProgressInfo from "../../components/primitives/ProjectProgressInfo";
import useHaulUpdate from "../../api/mutations/useHaulUpdate";
import JobHelper from "../../helpers/jobHelper";

dayjs.extend(customParseFormat);

const customDot = () => <div className="dot"></div>;

const getColorByStatus = (status) => {
  const statusColors = {
    Hauling: "#00B336",
    Assigned: "#6F55FF",
    Unassigned: "#DA6944",
  };
  return statusColors[status] || "#000000";
};

//Create HandleJob Compoennt
function ProjectDetails() {
  const { job_id } = useParams();
  const { data: job, isLoading } = useJob(job_id);
  const navigate = useNavigate();

  const { mutate: updateHaul, isPending: isUpdating } = useHaulUpdate({
    onSuccess: () => {
      navigate(`/projects/completed`);
    },
  });

  const { data: hauls, refetch } = useHauls({
    job_id,
    enabled: () => {
      if (job !== undefined) {
        const jobHelper = new JobHelper(job);
        return jobHelper.isAssigned;
      }
      return false;
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  dayjs.extend(customParseFormat);

  if (isLoading) {
    return <Loader />;
  }

  const statuses = [
    {
      title: "Unassigned",
      description: "10/13/24 - 10/15/24 ",
    },
    {
      title: "Assigned",
    },
    {
      title: "Hauling",
    },
    {
      title: "Completed",
    },
    {
      title: "Deadline",
    },
  ];

  const jobData = new JobHelper(job);
  const status = jobData.jobStatus;

  const { firstName = "", lastName = "" } = jobData.companyDetails || {};
  const fullName = `${firstName} ${lastName}`;

  const currentStatusIndex = (status) => {
    return statuses.findIndex((s) => s.title === status);
  };

  const completeProject = () => {
    const payload = {
      job_id,
      method: "completeHaul",
    };
    updateHaul(payload);
  };

  return (
    <div className="md:py-4 md:px-24 px-8  space-y-8">
      <div className="flex justify-between">
        <h1 className="text-2xl flex-shrink-0">
          Project: {jobData.projectName}
        </h1>

        <div className="flex items-center gap-4">
          <h1 className="text-2xl flex-shrink-0">Status: </h1>
          <Button
            type="primary"
            color="primary"
            style={{
              backgroundColor: getColorByStatus(status),
              color: "white",
            }}
            shape="round"
            size="large"
            className=" pointer-events-none"
          >
            {status}
          </Button>
        </div>
      </div>

      <Steps
        current={currentStatusIndex(status)}
        labelPlacement="horizontal"
        progressDot={customDot}
        items={statuses}
      />

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-32">
        <div>
          <div className="flex gap-4">
            <div className="flex-1 space-y-4">
              <CardWrap>
                <h4 className="font-semibold text-lg">
                  Date and Time of Delivery
                </h4>
                <div className="">
                  <h3 className="text-md font-semibold">Specific Date</h3>
                  <h3 className="text-grey-light">
                    {moment(jobData.job_date_ts).format("MM-DD-YYYY")}
                  </h3>
                </div>
              </CardWrap>

              <CardWrap>
                <h4 className="font-semibold text-lg">
                  Required Documentation
                </h4>
                <div className="">
                  <h3 className="text-md font-semibold">
                    There are 3 files uploaded
                  </h3>
                </div>
              </CardWrap>

              <CardWrap>
                <h4 className="font-semibold text-lg">Material</h4>
                <div className="">
                  <h3 className="text-md font-semibold">
                    Type of Rocks or Material
                  </h3>
                  <h3 className="text-grey-light">{jobData.materialType}</h3>
                </div>

                <div className="">
                  <h3 className="text-md font-semibold">
                    Total Amount of Rocks
                  </h3>
                  <h3 className="text-grey-light">
                    {jobData.totalMaterial} Tons
                  </h3>
                </div>
              </CardWrap>

              <CardWrap>
                <h4 className="font-semibold text-lg">Contact Information</h4>
                <div className="">
                  <h3 className="text-md font-semibold">Contact Person</h3>
                  <h3 className="text-grey-light">{fullName}</h3>
                </div>

                <div className="">
                  <h3 className="text-md font-semibold">Phone Number</h3>
                  <h3 className="text-grey-light">
                    {jobData.companyDetails.phoneNumber || "-"}
                  </h3>
                </div>
              </CardWrap>
            </div>
            <div className="flex-1 space-y-4">
              <CardWrap>
                <h4 className="font-semibold text-lg">Location</h4>
                {jobData.isGpsNotAvailable ? (
                  <h4 className="text-grey-light">
                    GPS Coordinates Not Available
                  </h4>
                ) : (
                  <iframe
                    title="map"
                    width="100%"
                    id="gmap_canvas"
                    className="rounded"
                    src={[
                      "https://maps.google.com/maps?q=",
                      jobData.gps,
                      "&t=&z=13&ie=UTF8&iwloc=&output=embed",
                    ].join("")}
                  ></iframe>
                )}

                <div className="">
                  <h3 className="text-md font-semibold">Construction Site</h3>
                  <h3 className="text-grey-light">
                    {jobData.address.address},{jobData.address.city},
                    {jobData.address.state},{jobData.address.zipcode}
                  </h3>
                </div>
                <div className="">
                  <h3 className="text-md font-semibold">GPS Coordinates</h3>
                  <h3 className="text-grey-light">
                    {jobData.gpsCoordinates.lat}° N,{" "}
                    {jobData.gpsCoordinates.lng}° W
                  </h3>
                </div>
              </CardWrap>
              <CardWrap>
                <h4 className="font-semibold text-lg">Project Notes</h4>

                <div className="">
                  <h3 className="text-md font-semibold">
                    Special Requirements
                  </h3>
                  <h3 className="text-grey-light">
                    {jobData.specialInstructions}
                  </h3>
                </div>
              </CardWrap>
            </div>
          </div>
        </div>
        <div>
          <div className="space-y-4">
            <ProjectProgressInfo
              percent={jobData.completedInPercent}
              total={jobData.totalMaterial}
              completed={jobData.completedTons}
            />

            {!jobData.isAssigned && (
              <Button onClick={showModal}>Start Project</Button>
            )}

            {jobData.isAssigned &&
              hauls?.map((haul, index) => {
                const haulData = new HaulAssignmentHelper(haul);

                const tripInfo = {
                  day: index + 1,
                  date: haulData.data.date,
                  tonsCompleted: haulData.tonsCompleted,
                  status: haulData.status,
                };

                return (
                  <TripInfo {...tripInfo}>
                    {haulData.assignments.map((assignment) => {
                      const assignmentHelper = new AssignmentHelper(assignment);
                      return (
                        <DriverTripDropdown
                          titleOne={assignmentHelper.driverName}
                          tonsCompleted={assignmentHelper.totalLoadCompleted}
                        />
                      );
                    })}
                  </TripInfo>
                );
              })}

            {jobData.isAssigned && (
              <div className="flex justify-center gap-4 mt-24">
                <Button onClick={showModal}>Start New Day</Button>
                <Button loading={isUpdating} onClick={completeProject}>
                  Complete Project
                </Button>
              </div>
            )}

            <Modal
              centered
              open={isModalOpen}
              footer={null}
              onCancel={handleCancel}
            >
              <ManageProject
                onSuccess={() => {
                  queryClient.invalidateQueries({
                    queryKey: [Queries.job],
                  });
                  refetch();
                  setIsModalOpen(false);
                }}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
