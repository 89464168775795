export const Queries = {
  trucks: "trucks",
  drivers: "drivers",
  jobs: "jobs",
  job: "job",
  hauls: "hauls",
  driverProjects: "driver_projects",
  quarries: "quarries",
  mines: "mines",
};
