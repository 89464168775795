const { ErrorMessage } = require("formik");

const FormikErrorMessage = ({ name }) => {
  return (
    <ErrorMessage
      name={name}
      component="div"
      className="text-sm"
      style={{ color: "#be3d3f" }}
    />
  );
};

export default FormikErrorMessage;
