const SendIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4126_3595"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="20"
      >
        <rect x="0.894531" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4126_3595)">
        <path
          d="M11.6445 17.5L9.26953 11.625L3.39453 9.25V8.08333L18.3945 2.5L12.8112 17.5H11.6445ZM12.1862 14.4167L15.5612 5.33333L6.47786 8.70833L10.5612 10.3333L12.1862 14.4167Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default SendIcon;
