import { useMutation } from "@tanstack/react-query";
import { submitHaul } from "../services/trucker";
import { Mutations } from ".";

const useHaulMutation = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: submitHaul,
    mutationKey: [Mutations.truckerHauls],
    onSuccess,
  });
};

export default useHaulMutation;
