import { useQuery } from "@tanstack/react-query";
import { Queries } from ".";
import { fetchJobs } from "../services/jobs";

const useJob = (job_id) => {
  return useQuery({
    queryKey: [Queries.job, job_id],
    queryFn: () => fetchJobs({ job_id }),
  });
};

export default useJob;
