import { useFormikContext } from "formik";
import $projectManagement from "../../../store/manage-project";
import useQuarries from "../../../api/queries/useQuarries";
import useMines from "../../../api/queries/useMines";
import { useState } from "react";
import { Form, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import FormikErrorMessage from "../../primitives/FormikErrorMessage";

const SelectQuarryAndMine = () => {
  const form = useFormikContext();
  const [selectedQuarry, setSelectedQuarry] = useState(null);
  const [selectedMine, setSelectedMine] = useState(null);
  const { data: quarries, isLoading: quariesLoading } = useQuarries();
  const { data: mines } = useMines({
    company_id: selectedQuarry,
    enabled: !!selectedQuarry,
  });

  const selectedTruckId = $projectManagement.selectors.useSelectedTruckId();

  if (quariesLoading)
    return (
      <div className="flex items-center justify-center h-24 ">
        <LoadingOutlined />
      </div>
    );

  const quaryOptions = quarries.map((e) => ({
    label: e.first_name + " " + e.last_name,
    value: e.company_id,
  }));

  const minesOptions = mines?.map((e) => ({
    label: e.mine_name,
    value: e._id,
  }));

  const quaryId = selectedTruckId + ".quarry_id";
  const mineId = selectedTruckId + ".mine_id";

  return (
    <div className="space-y-4">
      <div className="w-full min-w-[300px] max-h-[300px] overflow-scroll min-h-[300px] bg-dark-bg rounded-md p-4">
        <Form.Item name="quarry" label="Quarry">
          <Select
            labelInValue
            className="w-full"
            options={quaryOptions}
            values={selectedQuarry}
            onChange={(selected) => {
              form.setFieldValue(quaryId, selected.value);
              form.setFieldValue(mineId, null);
              setSelectedQuarry(selected.value);
            }}
          />
        </Form.Item>
        <FormikErrorMessage name={quaryId} />

        <Form.Item name="mine" label="Mine">
          <Select
            labelInValue
            className="w-full"
            options={minesOptions}
            values={selectedMine}
            onChange={(selected) => {
              form.setFieldValue(mineId, selected.value);
              setSelectedMine(selected.value);
            }}
          />
        </Form.Item>
        <FormikErrorMessage name={mineId} />
      </div>
    </div>
  );
};

export default SelectQuarryAndMine;
